import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/tr';
import { KTSVG, toAbsoluteUrl } from 'src/_metronic/helpers';
import { useAppSelector } from 'src/services/redux/app/store';

const TermsAndConditions: FC = () => {
  const { logs } = useAppSelector((state) => state.logs);
  return (
    <div
      id="kt_terms_and_conditions"
      className="bg-white"
      data-kt-drawer="true"
      data-kt-drawer-name="terms_and_conditions"
      data-kt-drawer-activate="true"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'300px', 'lg': '400px'}"
      data-kt-drawer-direction="end"
      data-kt-drawer-toggle="#kt_terms_and_conditions_toggle"
      data-kt-drawer-close="#kt_terms_and_conditions_close"
    >
      <div className="card shadow-none rounded-0 w-100">
        <div className="card-header" id="kt_terms_and_conditions_header">
          <h3 className="card-title fw-bolder text-dark">Şartlar ve Koşullar</h3>

          <div className="card-toolbar">
            <button type="button" className="btn btn-sm btn-icon btn-active-light-primary me-n5" id="kt_terms_and_conditions_close">
              <KTSVG path="/media/icons/duotone/Navigation/Close.svg" className="svg-icon-1" />
            </button>
          </div>
        </div>
        <div className="card-body position-relative" id="kt_terms_and_conditions_body">
          <div
            id="kt_terms_and_conditions_scroll"
            className="position-relative scroll-y me-n5 pe-5"
            data-kt-scroll="true"
            data-kt-scroll-height="auto"
            data-kt-scroll-wrappers="#kt_terms_and_conditions_body"
            data-kt-scroll-dependencies="#kt_terms_and_conditions_header, #kt_terms_and_conditions_footer"
            data-kt-scroll-offset="5px"
          >
            <div className="d-flex flex-column">
              <div className="fw-bolder fs-4 mb-4 text-decoration-underline">Eduthon Davet Et ve Kazan Özel Şartları</div>
              <div className="fw-bold fs-6">Davet Edilme Süreci:</div>
              <li className="d-flex align-items-center py-2">
                <span className="bullet me-5"></span>Eduthon kullanıcıları, platformda kayıtlı olmayan kurumları Eduthon'a davet edebilirler.
              </li>
              <li className="d-flex align-items-center py-2">
                <span className="bullet me-5"></span>Davet işlemi, kullanıcının Eduthon platformunda sağlanan "Davet Et" özelliği aracılığıyla gerçekleştirilmelidir.
              </li>
              <div className="fw-bold fs-6">Katılımcılar:</div>
              <li className="d-flex align-items-center py-2">
                <span className="bullet me-5"></span>Davet eden kişi, Eduthon platformunda aktif ve geçerli bir kullanıcı olmalıdır.
              </li>
              <li className="d-flex align-items-center py-2">
                <span className="bullet me-5"></span>Davet edilen kurum, daha önce Eduthon'a kayıtlı olmamalıdır.
              </li>
              <div className="fw-bold fs-6">Ödül:</div>
              <li className="d-flex align-items-center py-2">
                <span className="bullet me-5"></span>Davet edilen kurumun Eduthon'a kayıt olması ve Eduthon platformunda aktif bir kullanıcı haline gelmesi durumunda, davet eden
                kişiye 3 ay süreyle ücretsiz Eduthon aboneliği kazandırılacaktır.
              </li>
              <li className="d-flex align-items-center py-2">
                <span className="bullet me-5"></span>Abonelik süresi, davetin başarılı bir şekilde tamamlanmasından itibaren 3 ay olarak hesaplanacaktır.
              </li>
              <div className="fw-bold fs-6">Ödül Teslimi:</div>
              <li className="d-flex align-items-center py-2">
                <span className="bullet me-5"></span>Kazanılan abonelik süresi, davet eden kullanıcının Eduthon hesabına otomatik olarak tanımlanacaktır.
              </li>
              <li className="d-flex align-items-center py-2">
                <span className="bullet me-5"></span>Abonelik süresi, başka bir kullanıcıyla veya kurumla paylaşılamaz, devredilemez veya nakde çevrilemez.
              </li>
              <div className="fw-bold fs-6">Kuralların Değiştirilmesi:</div>
              <li className="d-flex align-items-center py-2">
                <span className="bullet me-5"></span>Eduthon, bu özel şartları herhangi bir zamanda önceden bildirimde bulunmaksızın değiştirme veya güncelleme hakkını saklı tutar.
              </li>
              <li className="d-flex align-items-center py-2">
                <span className="bullet me-5"></span>Değişiklikler, Eduthon platformunda yayınlanacak ve değiştirilen veya güncellenen şartlar, yayın tarihinden itibaren geçerli
                olacaktır.
              </li>
              <div className="fw-bold fs-6">Kabul Edilme:</div>
              <li className="d-flex align-items-center py-2">
                <span className="bullet me-5"></span>Bu özel şartları kabul ederek, kullanıcılar yukarıdaki kurallar ve şartlar dahilinde davet işlemi gerçekleştirmeyi kabul etmiş
                sayılırlar.
              </li>
            </div>
          </div>
        </div>
        {/* <div className="card-footer py-5 text-center" id="kt_terms_and_conditions_footer">
          <Link to="/crafted/pages/profile" className="btn btn-bg-white text-primary">
            View All terms_and_conditions
            <KTSVG path="/media/icons/duotone/Navigation/Right-2.svg" className="svg-icon-3 svg-icon-primary" />
          </Link>
        </div> */}
      </div>
    </div>
  );
};

export { TermsAndConditions };
