/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import moment from 'moment';
import 'moment/locale/tr';
import { useAppSelector } from 'src/services/redux/app/store';
import { KTSVG, toAbsoluteUrl, defaultAlerts } from '../../../helpers';
import './styles/styles.css';

type Props = {
  show: boolean;
};
const HeaderNotificationsMenu: FC<Props> = ({ show }) => {
  const { notifications } = useAppSelector((state) => state.notifications);
  return (
    <div className={`menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px`} data-kt-menu="true">
      <div className="d-flex flex-column bgi-no-repeat rounded-top" style={{ backgroundImage: `url('${toAbsoluteUrl('/media/misc/pattern-1.jpg')}')` }}>
        <h3 className="text-white fw-bold px-9 mt-10 mb-6">Bildirimler</h3>

        <ul className="nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold px-9">
          <li className="nav-item">
            <a className="nav-link text-white opacity-75 opacity-state-100 pb-4 active" data-bs-toggle="tab" href="#kt_topbar_notifications_1">
              Genel
            </a>
          </li>

          <li className="nav-item">
            <a className="nav-link text-white opacity-75 opacity-state-100 pb-4 " data-bs-toggle="tab" href="#kt_topbar_notifications_2">
              Özel
            </a>
          </li>
        </ul>
      </div>

      <div className="tab-content">
        <div className="tab-pane fade show active" id="kt_topbar_notifications_1" role="tabpanel">
          <div className="scroll-y mh-325px my-5 px-8">
            {notifications && notifications?.results?.length > 0 ? (
              notifications?.results?.map((alert, index) => (
                <div key={`alert${index}`} className="d-flex flex-stack py-4">
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-35px me-4">
                      <span className={clsx('symbol-label', `bg-light-${alert?.title}`)}>
                        {' '}
                        <KTSVG path={`/media/icons/duotone/Communication/Flag.svg`} className={`svg-icon-2 svg-icon-primary`} />
                      </span>
                    </div>

                    <div className="mb-0 me-2">
                      <a href="#" className="fs-6 text-gray-800 text-hover-primary fw-bolder">
                        {alert?.title}
                      </a>
                      <div className="text-gray-400 fs-7">{alert?.content}</div>
                    </div>
                  </div>

                  <span className="badge badge-light fs-8">{moment(alert.created_at).fromNow()}</span>
                </div>
              ))
            ) : (
              <div className="d-flex flex-column px-9">
                <div className="pt-10 pb-0">
                  <h3 className="text-dark text-center fw-bolder">Genel Bildirim</h3>

                  <div className="text-center text-gray-600 fw-bold pt-1">Henüz genel bildiriminiz bulunmuyor.</div>
                </div>

                <div className="text-center px-4">
                  <img className="mw-100 mh-200px" alt="metronic" src={toAbsoluteUrl('/media/illustrations/work.png')} />
                </div>
              </div>
            )}
          </div>

          <div className="py-3 text-center border-top">
            <a className="btn btn-color-gray-600 btn-active-color-primary">
              Tümünü Gör <KTSVG path="/media/icons/duotone/Navigation/Right-2.svg" className="svg-icon-5" />
            </a>
          </div>
        </div>

        <div className="tab-pane fade " id="kt_topbar_notifications_2" role="tabpanel">
          <div className="d-flex flex-column px-9">
            <div className="pt-10 pb-0">
              <h3 className="text-dark text-center fw-bolder">Özel Bildirim</h3>

              <div className="text-center text-gray-600 fw-bold pt-1">Henüz özel bildiriminiz bulunmuyor.</div>
            </div>

            <div className="text-center px-4">
              <img className="mw-100 mh-200px" alt="metronic" src={toAbsoluteUrl('/media/illustrations/work.png')} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { HeaderNotificationsMenu };
