import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap-v5';
import { toast } from 'react-toastify';
import { Formik, FormikProps, FormikValues } from 'formik';
import { KTSVG } from 'src/_metronic/helpers';
import { _setStamp } from 'src/services/redux/slices/auth/auth-slice';
import * as Yup from 'yup';
import { useBranchesDelete } from '../service';

type Props = {
  show: boolean;
  handleClose: Function;
  detailList: any;
  refetchList: any;
};

export default function BranchesDelete({ show, handleClose, detailList, refetchList }: Props) {
  const formikRef = useRef<FormikProps<any>>(null);
  const onSuccessFetch = (e: any) => {
    toast.success(`Şube başarıyla silindi.`);
    refetchList();
    dissmisLocation();
  };
  const onErrorFetch = (e: any) => e?.response?.data?.message && toast.error(e?.response?.data?.message);
  const { isLoading, mutate, reset } = useBranchesDelete(onSuccessFetch, onErrorFetch);

  const dissmisLocation = () => {
    formikRef?.current?.resetForm();
    handleClose();
  };

  return (
    <Modal show={show} onHide={dissmisLocation} centered>
      <Formik
        innerRef={formikRef}
        initialValues={{ id: detailList?.id }}
        onSubmit={(values, { setSubmitting }) => {
          mutate(values);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Kurucu Sil
                </h5>
                <button type="button" className="btn-close" onClick={dissmisLocation} aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="alert alert-secondary d-flex align-items-center p-5 mb-10">
                  <span className="svg-icon svg-icon-2hx svg-icon-primary me-3">
                    <KTSVG path="/media/icons/duotone/Home/Circle-info-solid.svg" className="svg-icon-primary text-primary me-5  " />
                  </span>

                  <div className="d-flex flex-column">
                    <span className="fs-7">
                      <b>{detailList?.company_name}</b> şubesini silmek istediğinize emin misiniz ?
                    </span>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={dissmisLocation}>
                  Vazgeç
                </button>
                <button type="submit" className="btn btn-light-danger" disabled={isSubmitting}>
                  Sil
                  {isLoading && <span className="spinner-border spinner-border-sm align-middle mx-2 text-white"></span>}
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
}
