/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { KTSVG } from '../../../helpers';
import { Dropdown1 } from '../../content/dropdown/Dropdown1';

type Props = {
  className: string;
  color: string;
  dashboardList?: any;
};

const MixedWidget1: React.FC<Props> = ({ className, color, dashboardList }) => {
  const suAnkiTarih = moment();
  var startDate = suAnkiTarih.clone().startOf('month').format('DD.MM.YYYY');

  var endDate = suAnkiTarih.clone().endOf('month').format('DD.MM.YYYY');
  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className="card-body p-0">
        {/* begin::Header */}
        <div className={`px-9 pt-7 card-rounded h-250px w-100 bg-${color}`}>
          {/* begin::Heading */}
          <div className="d-flex flex-stack">
            <h3 className="m-0 text-white fw-bolder fs-3">Aylık Öğrenci Geliri</h3>
          </div>
          {/* end::Heading */}
          {/* begin::Balance */}
          <div className="d-flex text-center flex-column text-white pt-8">
            <span className="fw-bold fs-7">Aylık Finans Bilgisi</span>
            <span className="fw-bold fs-7">
              {startDate} / {endDate}
            </span>
            <span className="fw-bolder fs-2x pt-1">{dashboardList?.total_price ? dashboardList?.total_price?.toLocaleString('tr-TR') : 0} ₺</span>
          </div>
          {/* end::Balance */}
        </div>
        {/* end::Header */}
        {/* begin::Items */}
        <div className="shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-white" style={{ marginTop: '-100px' }}>
          {/* begin::Item */}
          <div className="d-flex align-items-center mb-6">
            {/* begin::Symbol */}
            <div className="symbol symbol-45px w-40px me-5">
              <span className="symbol-label bg-lighten">
                <KTSVG path="/media/icons/duotone/Home/Globe.svg" className="svg-icon-1" />
              </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Description */}
            <div className="d-flex align-items-center flex-wrap w-100">
              {/* begin::Title */}
              <div className="mb-1 pe-3 flex-grow-1">
                <Link to={'/finance/income/list'} className="fs-5 text-gray-800 text-hover-primary fw-bolder">
                  Gelir
                </Link>
                <div className="text-gray-400 fw-bold fs-7"></div>
              </div>
              {/* end::Title */}
              {/* begin::Label */}
              <div className="d-flex align-items-center">
                <div className="fw-bolder fs-5 text-gray-800 pe-1">{dashboardList?.income ? dashboardList?.income?.toLocaleString('tr-TR') : 0} ₺</div>
                <KTSVG path="/media/icons/duotone/Navigation/Arrow-up.svg" className="svg-icon-5 svg-icon-success ms-1" />
              </div>
              {/* end::Label */}
            </div>
            {/* end::Description */}
          </div>
          {/* end::Item */}
          {/* begin::Item */}
          <div className="d-flex align-items-center mb-6">
            {/* begin::Symbol */}
            <div className="symbol symbol-45px w-40px me-5">
              <span className="symbol-label bg-lighten">
                <KTSVG path="/media/icons/duotone/Layout/Layout-4-blocks-2.svg" className="svg-icon-1" />
              </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Description */}
            <div className="d-flex align-items-center flex-wrap w-100">
              {/* begin::Title */}
              <div className="mb-1 pe-3 flex-grow-1">
                <Link to={'/finance/income/list'} className="fs-5 text-gray-800 text-hover-primary fw-bolder">
                  Gider
                </Link>
                <div className="text-gray-400 fw-bold fs-7"></div>
              </div>
              {/* end::Title */}
              {/* begin::Label */}
              <div className="d-flex align-items-center">
                <div className="fw-bolder fs-5 text-gray-800 pe-1">{dashboardList?.expense ? dashboardList?.expense?.toLocaleString('tr-TR') : 0} ₺</div>
                <KTSVG path="/media/icons/duotone/Navigation/Arrow-down.svg" className="svg-icon-5 svg-icon-danger ms-1" />
              </div>
              {/* end::Label */}
            </div>
            {/* end::Description */}
          </div>
          {/* end::Item */}
          {/* begin::Item */}
          <div className="d-flex align-items-center mb-6">
            {/* begin::Symbol */}
            <div className="symbol symbol-45px w-40px me-5">
              <span className="symbol-label bg-lighten">
                <KTSVG path="/media/icons/duotone/Devices/Watch2.svg" className="svg-icon-1" />
              </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Description */}
            <div className="d-flex align-items-center flex-wrap w-100">
              {/* begin::Title */}
              <div className="mb-1 pe-3 flex-grow-1">
                <Link to={'/finance/operations/create'} className="fs-5 text-gray-800 text-hover-primary fw-bolder">
                  Ödeme Oluştur
                </Link>
              </div>
              {/* end::Title */}
              {/* begin::Label */}
              <div className="d-flex align-items-center">
                <div className="fw-bolder fs-5 text-gray-800 pe-1">-</div>
              </div>
              {/* end::Label */}
            </div>
            {/* end::Description */}
          </div>
          {/* end::Item */}
          {/* begin::Item */}
          <div className="d-flex align-items-center">
            {/* begin::Symbol */}
            <div className="symbol symbol-45px w-40px me-5">
              <span className="symbol-label bg-lighten">
                <KTSVG path="/media/icons/duotone/General/Clipboard.svg" className="svg-icon-1" />
              </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Description */}
            <div className="d-flex align-items-center flex-wrap w-100">
              {/* begin::Title */}
              <div className="mb-1 pe-3 flex-grow-1">
                <Link to={'/finance/operations/end-of-day-report'} className="fs-5 text-gray-800 text-hover-primary fw-bolder">
                  Gün Sonu Raporu
                </Link>
              </div>
              {/* end::Title */}
              {/* begin::Label */}
              <div className="d-flex align-items-center">
                <div className="fw-bolder fs-5 text-gray-800 pe-1">-</div>
              </div>
              {/* end::Label */}
            </div>
            {/* end::Description */}
          </div>
          {/* end::Item */}
        </div>
        {/* end::Items */}
      </div>
      {/* end::Body */}
    </div>
  );
};

export { MixedWidget1 };
