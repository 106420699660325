/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react';
import ApexCharts, { ApexOptions } from 'apexcharts';
import moment from 'moment';
import { getCSS, getCSSVariableValue } from 'src/_metronic/assets/ts/_utils';

type Props = {
  className: string;
  chartData: any;
};

const BankChart: React.FC<Props> = ({ className, chartData }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (!chartRef.current || !chartData) {
      return;
    }

    const height = parseInt(getCSS(chartRef.current, 'height'));

    const chart = new ApexCharts(chartRef.current, getChartOptions(height, chartData));
    if (chart) {
      chart.render();
    }

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef, chartData]);

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder fs-3 mb-1">Banka İstatistikleri</span>
        </h3>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body">
        {/* begin::Chart */}
        <div ref={chartRef} id="kt_charts_widget_3_chart" style={{ height: '350px' }}></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  );
};

export { BankChart };

function getRandomNumber(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function getChartOptions(height: number, chartData: any): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500');
  const borderColor = getCSSVariableValue('--bs-gray-200');
  const baseColor = getCSSVariableValue('--bs-info');

  const colors = [
    {
      bs_light: getCSSVariableValue('--bs-info'),
      bs: getCSSVariableValue('--bs-info'),
    },
    {
      bs_light: getCSSVariableValue('--bs-warning'),
      bs: getCSSVariableValue('--bs-warning'),
    },
    {
      bs_light: getCSSVariableValue('--bs-success'),
      bs: getCSSVariableValue('--bs-success'),
    },
    {
      bs_light: getCSSVariableValue('--bs-danger'),
      bs: getCSSVariableValue('--bs-danger'),
    },
    {
      bs_light: getCSSVariableValue('--bs-secondary'),
      bs: getCSSVariableValue('--bs-secondary'),
    },
  ];

  const series = chartData?.map((cash: any, index: number) => ({
    name: cash?.account_name,
    data: cash?.merged_data?.map(
      (merged: any) =>
        Number(merged?.total_price_payments) +
        Number(merged?.total_price_income_expenses) +
        Number(merged?.total_price_current_account) +
        Number(merged?.total_transfer_price_expenses) +
        Number(merged?.total_transfer_price_income),
    ),
  }));

  const categories = chartData?.map((cash: any) => cash?.merged_data?.map((merged: any) => moment(merged?.transaction_date).fromNow()));
  const uniqueDates = Array.from(new Set(categories.flat()));
  const colorsData = chartData?.map((cash: any, index: number) => {
    const randomIndex = getRandomNumber(0, colors.length - 1);
    return colors[randomIndex];
  });
  return {
    series: series,
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: 350,
      toolbar: {
        show: true,
      },
    },
    plotOptions: {},
    legend: {
      show: true,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 0.3,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: colorsData?.map((color: any) => color?.bs),
    },
    xaxis: {
      categories: uniqueDates,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: false,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return Number(val)?.toLocaleString('tr-TR') + ' TL';
        },
      },
    },
    colors: colorsData?.map((color: any) => color?.bs_light),
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: baseColor,
      strokeWidth: 3,
    },
  };
}
