const steps = [
  {
    target: '.account-settings',
    content: 'Burada hesap ayarları, şubeler, ödeme bilgileri ve komisyon bilgilerini görebilir ve düzenleyebilirsiniz.',
  },
  {
    target: '.dark-mode',
    content: 'Buradan tema ayarlarınızı güncelleyebilirsiniz. Karanlık mod veya açık mod kullanabilirsiniz.',
  },
  {
    target: '.notification-joyride',
    content: 'Gelen kurum bildirimlerinizi ve özel bildirimleri buradan takip edebilirsiniz.',
  },
  {
    target: '.message-joyride',
    content: 'Gelen mesajlarınızı yönetebilirsiniz.',
  },
  {
    target: '.product-joyride',
    content: 'Bu kısımdan Yoklama cihazı, Servis takip cihazı, Kantin cihazı ve Zil cihazlarını sipariş verebilir ve siparişleriniz takip edebilirsiniz.',
  },
  {
    target: '.email-joyride',
    content: 'Bu kısımdan email hesaplarınızı kontol edip gelen emaillerinizi kolaylıkla yönetebilirsiniz.',
  },
  {
    target: '.periods-joyride',
    content: 'Bu kısımdan aktif ve pasif dönemlerinizi düzenleyebilirsiniz.',
  },
  {
    target: '.notes-joyride',
    content: 'Bu kısımdan kişisel notlarınızı ekleyebilirsiniz ve düzenleyebilirsiniz.',
  },
  {
    target: '.users-joyride',
    content: 'Bu kısımdan kurumun kullanıcılarını yönetebilirsiniz.',
  },
  {
    target: '.registration-joyride',
    content: 'Bu kısımdan kurumun ön kayıt ve kesin kayıt işlemlerini yönetebilirsiniz.',
  },
  {
    target: '.site-joyride',
    content: 'Bu kısımdan kurumsal web sitenizin tüm ayrıntılarını güncelleyebilirsiniz.',
  },
  {
    target: '.personal-joyride',
    content: 'Bu kısımdan personelerinizi yönetebilirsiniz.',
  },
  {
    target: '.lessons-joyride',
    content: 'Bu kısımdan derslerinizi düzenleyip yönetebilirsiniz.',
  },
  {
    target: '.homeworks-joyride',
    content: 'Bu kısımdan öğrencilerinize ödev verip değerlendirebilirsiniz.',
  },
  {
    target: '.finance-joyride',
    content: 'Finans kısmından kurumun tüm finans işlemlerini yönetebilirsiniz.',
  },
  {
    target: '.bulk-joyride',
    content: 'Toplu işlemler sekmesinden veri aktarımını daha hızlı gerçekleştirebilirsiniz.',
  },
  {
    target: '.device-joyride',
    content: 'Cihaz işlemlerinde satın alınan ürünlerinizi görüntüleyebilir ve cihazınızın tüm işlemlerini gerçekleştirebilirsiniz.',
  },
  {
    target: '.report-joyride',
    content: 'Kurumunuzun tüm raporlamalarını bu kısımdan alabilirsiniz.',
  },
  {
    target: '.ticket-joyride',
    content: 'Bu kısımda herhangi bir sorunuz olması halinde teknik ekip ile iletişime geçebilirsiniz.',
  },
  {
    target: '.release-joyride',
    content: 'Eduthon sisteminde yapılan tüm güncellemeleri burada kontrol edebilirsiniz.',
  },
];

export default steps;
