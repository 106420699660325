import isURL from 'validator/lib/isURL';
import * as Yup from 'yup';

export interface ICreateAccount {
  contact_name: string;
  contact_surname: string;
  contact_phone: string;
  email: string;
  company_name: string;
  tax_number: string;
  web_site: string;
  password: string;
  changepassword: string;
  is_invite: boolean;
  invitation_code: string;
}

const urlOptions = {
  require_tld: true, // Üst seviye alan adını (TLD) zorunlu kıl
  require_host: true, // Ana bilgisayarı (host) zorunlu kıl
  require_valid_protocol: true, // Geçerli bir protokolü zorunlu kıl (örneğin http, https)
  protocols: ['http', 'https'], // İzin verilen protokoller
};

const createAccountSchemas = [
  Yup.object({
    contact_name: Yup.string().min(3, 'Minimum 3 karakter belirtin').max(50, 'Maksimum 50 karakter belirtin').required('İletişim için adınızı belirtin.'),
    contact_surname: Yup.string().min(3, 'Minimum 3 karakter belirtin').max(50, 'Maksimum 50 karakter belirtin').required('İletişim için soyadınızı belirtin.'),
    contact_phone: Yup.object().shape({
      floatValue: Yup.string().min(1).required(),
      formattedValue: Yup.string().min(1, 'Lütfen telefon numaranızı belirtin').required('Lütfen telefon numaranızı belirtin'),
      value: Yup.string().min(10, 'Lütfen geçerli bir telefon numarası belirtin').max(10, 'Lütfen geçerli bir telefon numarası belirtin').required(),
    }),
    email: Yup.string().email('Geçersiz email adresi girdiniz').min(3, 'Minimum 3 karakter').max(50, 'Maksimum 50 karakter').required('Email adresi zorunludur'),
  }),
  Yup.object({
    company_name: Yup.string().min(3, 'Minimum 3 karakter girin').max(50, 'Maksimum 50 karakter girin').required('Kurum adı zorunludur.'),
    tax_number: Yup.string().min(10, 'Minimum 10 karakter girin').max(10, 'Maksimum 10 karakter girin').required('Vergi numarası zorunludur.'),
    web_site: Yup.string()
      .lowercase()
      .test('isSlug', 'Lütfen geçerli bir web sitesi belirtin.', (val: any) => {
        return val && isURL(val, urlOptions);
      }),
  }),
  Yup.object({}),
  Yup.object({
    password: Yup.string()
      .required('Şifre gerekli')
      .matches(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/, '8 Karakter, Bir Büyük Harf, Bir Küçük Harf, Bir Sayı ve Bir Özel Durum Karakteri İçermelidir'),
    changepassword: Yup.string()
      .required('Şifre onayı gerekli')
      .when('password', {
        is: (val: string) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf([Yup.ref('password')], 'Şifreleriniz uyuşmuyor.'),
      }),
    is_invite: Yup.boolean(),
    invitation_code: Yup.string().when('is_invite', {
      is: (val: string) => {
        return val;
      },
      then: Yup.string().max(10, 'Lütfen 10 haneli davet kodunu girin.').min(10, 'Lütfen 10 haneli davet kodunu girin.').required('Lütfen davet kodunuzu girin.'),
    }),
  }),
];

const inits: ICreateAccount = {
  contact_name: '',
  contact_surname: '',
  contact_phone: '',
  email: '',
  tax_number: '',
  company_name: '',
  web_site: '',
  password: '',
  changepassword: '',
  is_invite: false,
  invitation_code: '',
};

export { createAccountSchemas, inits };
