import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import store from '../../app/store';

const REACT_APP_API_URL = `${process.env.REACT_APP_API_URL}`;

const initialState: IAuthState = {
  payment_details: null,
};

export interface IAuthState {
  payment_details?: any;
}
export const payment_detailsList = createAsyncThunk('payment_details/list', async () => {
  const company_id = store.getState()?.auth?.user?.company?.id;
  var params = { company_id };
  const res = await axios.get<any>(`${REACT_APP_API_URL}/iyzipay/market-place/workplace-inquiry`, { params });
  return res.data;
});

export const payment_detailsSlice = createSlice({
  name: 'EDpayment_details',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(payment_detailsList.fulfilled, (state, action) => {
      state.payment_details = action.payload;
    });
    builder.addCase(payment_detailsList.rejected, (state, action) => {
      state.payment_details = null;
    });
  },
});

export default payment_detailsSlice.reducer;
