import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import moment from 'moment';
import { useAppSelector } from 'src/services/redux/app/store';

const REACT_APP_API_URL = `${process.env.REACT_APP_API_URL}`;

export const useFinanceList = (params: any, onError?: any) => {
  const { user } = useAppSelector((state) => state.auth);
  const requestParams = { payment_id: user?.company_id, ...params };
  const requestUrl = REACT_APP_API_URL + '/payments/list';
  const fetchData = async () => {
    const res = await axios.get<any>(`${requestUrl}`, { params: requestParams });
    return res?.data || {};
  };
  return useQuery(['finance-list'], fetchData, {
    refetchOnWindowFocus: false,
    onError,
    staleTime: Infinity,
    retry: false,
  });
};
export const useFinanceDelete = (onSuccess?: any, onError?: any) => {
  const { user } = useAppSelector((state) => state.auth);
  const requestUrl = REACT_APP_API_URL + '/payments/delete';
  return useMutation(
    (formPayload: any) => {
      return axios.post(`${requestUrl}`, { ...formPayload, payment_id: user?.company_id });
    },
    { onError, onSuccess },
  );
};
export const useFinanceUpdate = (onSuccess?: any, onError?: any) => {
  const { user } = useAppSelector((state) => state.auth);
  const requestUrl = REACT_APP_API_URL + '/payments/update';
  return useMutation(
    (formPayload: any) => {
      return axios.post(`${requestUrl}`, { ...formPayload, payment_id: user?.company_id });
    },
    { onError, onSuccess },
  );
};
export const useFinanceCreate = (onSuccess?: any, onError?: any) => {
  const { user } = useAppSelector((state) => state.auth);
  const requestUrl = REACT_APP_API_URL + '/payments/create';
  return useMutation(
    (formPayload: any) => {
      return axios.post(`${requestUrl}`, { ...formPayload, payment_id: user?.company_id });
    },
    { onError, onSuccess },
  );
};
export const useRegistrationCertainList = (onError?: any) => {
  const { user } = useAppSelector((state) => state.auth);
  const requestParams = { id: user?.company_id, page: 1, limit: 1500 };
  const requestUrl = REACT_APP_API_URL + '/registration/certain/list';
  const fetchData = async () => {
    const res = await axios.get<any>(`${requestUrl}`, { params: requestParams });
    return res?.data || {};
  };
  return useQuery(['registration-list'], fetchData, {
    refetchOnWindowFocus: false,
    onError,
  });
};
export const usePaymentMethod = (onError?: any) => {
  const requestUrl = REACT_APP_API_URL + '/paymentmethod/list';
  const fetchData = async () => {
    const res = await axios.get(`${requestUrl}`);
    return res?.data || {};
  };
  return useQuery(['paymentmethod-list'], fetchData, {
    refetchOnWindowFocus: false,
    onError,
  });
};

export const useDebyInquiry = (onSuccess?: any, onError?: any) => {
  const { user } = useAppSelector((state) => state.auth);
  const requestUrl = REACT_APP_API_URL + '/payments/debt/inquiry';
  return useMutation(
    (formPayload: any) => {
      return axios.post(`${requestUrl}`, { ...formPayload, payment_id: user?.company_id });
    },
    { onError, onSuccess },
  );
};
export const useDebtPaymentsList = (params: any, onError?: any) => {
  const { user } = useAppSelector((state) => state.auth);
  const requestParams = { ...params, company_id: user?.company_id };
  const requestUrl = REACT_APP_API_URL + '/payments/debt/list';
  const fetchData = async () => {
    const res = await axios.get<any>(`${requestUrl}`, { params: requestParams });
    return res?.data || {};
  };
  return useQuery(['debt-payments-list'], fetchData, {
    refetchOnWindowFocus: false,
    onError,
    retry: false,
  });
};

export const useOpenHomeworkDetail = (params: any, onError?: any) => {
  const { user } = useAppSelector((state) => state.auth);
  const requestParams = { id: params, company_id: user?.company_id };
  const requestUrl = REACT_APP_API_URL + '/homeworks/detail/open';
  const fetchData = async () => {
    const res = await axios.get<any>(`${requestUrl}`, { params: requestParams });
    return res?.data || {};
  };
  return useQuery(['homeworks-detail-open'], fetchData, {
    refetchOnWindowFocus: false,
    onError,
    retry: false,
  });
};

export const useDebtNoticeList = (params: any, onError?: any) => {
  const { user } = useAppSelector((state) => state.auth);
  const requestParams = { ...params, company_id: user?.company_id };
  const requestUrl = REACT_APP_API_URL + '/payments/debt/notice/list';
  const fetchData = async () => {
    const res = await axios.get<any>(`${requestUrl}`, { params: requestParams });
    return res?.data || {};
  };
  return useQuery(['debt-notice-list'], fetchData, {
    refetchOnWindowFocus: false,
    onError,
    retry: false,
    enabled: false,
  });
};

export const useDebtNoticeCreate = (onSuccess?: any, onError?: any) => {
  const { user } = useAppSelector((state) => state.auth);
  const requestUrl = REACT_APP_API_URL + '/payments/debt/notice/create';
  return useMutation(
    (formPayload: any) => {
      return axios.post(`${requestUrl}`, { ...formPayload, company_id: user?.company_id });
    },
    { onError, onSuccess },
  );
};

export const useOnlinePaymentDetails = (params: any, onError?: any) => {
  const requestParams = { ...params };
  const requestUrl = REACT_APP_API_URL + '/payments/online/details';
  const fetchData = async () => {
    const res = await axios.get<any>(`${requestUrl}`, { params: requestParams });
    return res?.data || {};
  };
  return useQuery(['online-payment-list'], fetchData, {
    refetchOnWindowFocus: false,
    onError,
    retry: false,
    enabled: false,
  });
};

export const useEndOfDayReportInquiry = (onSuccess?: any, onError?: any) => {
  const { user } = useAppSelector((state) => state.auth);
  const requestUrl = REACT_APP_API_URL + '/payments/end-of-day-report';
  return useMutation(
    (formPayload: any) => {
      return axios.post(`${requestUrl}`, { ...formPayload, company_id: user?.company_id });
    },
    { onError, onSuccess },
  );
};

// export const useGibAuth = (onSuccess?: any, onError?: any) => {
//   const { user } = useAppSelector((state) => state.auth);
//   const requestUrl = REACT_APP_API_URL + '/payments/gib/auth';
//   return useMutation(
//     (formPayload: any) => {
//       return axios.post(`${requestUrl}`, { company_id: user?.company_id });
//     },
//     { onError, onSuccess },
//   );
// };

export const useGibAuth = (onError?: any) => {
  const { user } = useAppSelector((state) => state.auth);
  const requestUrl = REACT_APP_API_URL + '/payments/gib/auth';
  const requestParams = { company_id: user?.company_id };
  const fetchData = async () => {
    const res = await axios.get(`${requestUrl}`, { params: requestParams });
    return res?.data || {};
  };
  return useQuery(['gib-auth-gib'], fetchData, {
    refetchOnWindowFocus: false,
    onError,
    enabled: false,
  });
};

export const useGibInvoices = (params: any, onError?: any) => {
  const { user } = useAppSelector((state) => state.auth);
  const requestUrl = REACT_APP_API_URL + '/payments/gib/company/invoices';
  const requestParams = {
    ...params,
    company_id: user?.company_id,
    startDate: moment(params?.startDate).format('DD/MM/YYYY'),
    endDate: moment(params?.endDate).format('DD/MM/YYYY'),
  };
  const fetchData = async () => {
    const res = await axios.get(`${requestUrl}`, { params: requestParams });
    return res?.data || {};
  };
  return useQuery(['gib-invoices-list'], fetchData, {
    refetchOnWindowFocus: false,
    onError,
    enabled: false,
  });
};

export const useGibMyInvoices = (params: any, onError?: any) => {
  const { user } = useAppSelector((state) => state.auth);
  const requestUrl = REACT_APP_API_URL + '/payments/gib/company/listing-invoices';
  const requestParams = {
    ...params,
    company_id: user?.company_id,
    startDate: moment(params?.startDate).format('DD/MM/YYYY'),
    endDate: moment(params?.endDate).format('DD/MM/YYYY'),
  };
  const fetchData = async () => {
    const res = await axios.get(`${requestUrl}`, { params: requestParams });
    return res?.data || {};
  };
  return useQuery(['gib-listing-invoices-list'], fetchData, {
    refetchOnWindowFocus: false,
    onError,
    enabled: false,
  });
};

export const useGibHTML = (onSuccess?: any, onError?: any) => {
  const requestUrl = REACT_APP_API_URL + '/payments/gib/company/invoice-html';
  return useMutation(
    (formPayload: any) => {
      return axios.post(
        `${requestUrl}`,
        { ...formPayload },
        {
          responseType: 'text',
        },
      );
    },
    { onError, onSuccess },
  );
};

export const useGibDownloadUrl = (onSuccess?: any, onError?: any) => {
  const requestUrl = REACT_APP_API_URL + '/payments/gib/company/invoice-download-url';
  return useMutation(
    (formPayload: any) => {
      return axios.post(`${requestUrl}`, { ...formPayload });
    },
    { onError, onSuccess },
  );
};

export const useGibXML = (onSuccess?: any, onError?: any) => {
  const requestUrl = REACT_APP_API_URL + '/payments/gib/company/invoice-xml';
  return useMutation(
    (formPayload: any) => {
      return axios.post(
        `${requestUrl}`,
        { ...formPayload },
        {
          responseType: 'text',
        },
      );
    },
    { onError, onSuccess },
  );
};

export const useGibZIP = (onSuccess?: any, onError?: any) => {
  const requestUrl = REACT_APP_API_URL + '/payments/gib/company/invoice-zip';
  return useMutation(
    (formPayload: any) => {
      return axios.post(
        `${requestUrl}`,
        { ...formPayload },
        {
          responseType: 'arraybuffer',
        },
      );
    },
    { onError, onSuccess },
  );
};

export const useGibCompanyInfo = (params: any, onError?: any) => {
  const { user } = useAppSelector((state) => state.auth);
  const requestUrl = REACT_APP_API_URL + '/payments/gib/company/info';
  const requestParams = { ...params, company_id: user?.company_id };
  const fetchData = async () => {
    const res = await axios.get(`${requestUrl}`, { params: requestParams });
    return res?.data || {};
  };
  return useQuery(['gib-company-info'], fetchData, {
    refetchOnWindowFocus: false,
    onError,
    enabled: params?.username && params?.password ? true : false,
  });
};
export const useGibInfoUpdate = (onSuccess?: any, onError?: any) => {
  const { user } = useAppSelector((state) => state.auth);
  const requestUrl = REACT_APP_API_URL + '/payments/gib/company/info/update';
  return useMutation(
    (formPayload: any) => {
      return axios.post(`${requestUrl}`, { ...formPayload, company_id: user?.company_id });
    },
    { onError, onSuccess },
  );
};

export const useGibCreateInvoice = (onSuccess?: any, onError?: any) => {
  const { user } = useAppSelector((state) => state.auth);
  const requestUrl = REACT_APP_API_URL + '/payments/gib/draft-invoice';
  return useMutation(
    (formPayload: any) => {
      return axios.post(`${requestUrl}`, { ...formPayload, company_id: user?.company_id });
    },
    { onError, onSuccess },
  );
};

export const useGibTypes = (onError?: any) => {
  const { user } = useAppSelector((state) => state.auth);
  const requestUrl = REACT_APP_API_URL + '/payments/gib/types';
  const requestParams = { company_id: user?.company_id };
  const fetchData = async () => {
    const res = await axios.get(`${requestUrl}`, { params: requestParams });
    return res?.data || {};
  };
  return useQuery(['gib-types'], fetchData, {
    refetchOnWindowFocus: false,
    onError,
  });
};

export const useGibRecords = (onError?: any) => {
  const { user } = useAppSelector((state) => state.auth);
  const requestUrl = REACT_APP_API_URL + '/payments/gib/records';
  const requestParams = { company_id: user?.company_id };
  const fetchData = async () => {
    const res = await axios.get(`${requestUrl}`, { params: requestParams });
    return res?.data || {};
  };
  return useQuery(['gib-records'], fetchData, {
    refetchOnWindowFocus: false,
    onError,
  });
};

export const useGibDraftDelete = (onSuccess?: any, onError?: any) => {
  const { user } = useAppSelector((state) => state.auth);
  const requestUrl = REACT_APP_API_URL + '/payments/gib/draft-invoice-delete';
  return useMutation(
    (formPayload: any) => {
      return axios.post(`${requestUrl}`, { ...formPayload, company_id: user?.company_id });
    },
    { onError, onSuccess },
  );
};

export const useGibDraftSendSMS = (onSuccess?: any, onError?: any) => {
  const { user } = useAppSelector((state) => state.auth);
  const requestUrl = REACT_APP_API_URL + '/payments/gib/draft-invoice-send-sms';

  return useMutation(
    (formPayload: any) => {
      return axios.post(`${requestUrl}`, { ...formPayload, company_id: user?.company_id });
    },
    { onError, onSuccess },
  );
};

export const useGibDraftApprove = (onSuccess?: any, onError?: any) => {
  const { user } = useAppSelector((state) => state.auth);
  const requestUrl = REACT_APP_API_URL + '/payments/gib/draft-invoice-approve';
  return useMutation(
    (formPayload: any) => {
      return axios.post(`${requestUrl}`, { ...formPayload, company_id: user?.company_id });
    },
    { onError, onSuccess },
  );
};

export const useBanksList = (onError?: any) => {
  const { user } = useAppSelector((state) => state.auth);
  const requestParams = { company_id: user?.company_id };
  const requestUrl = REACT_APP_API_URL + '/banks/list';
  const fetchData = async () => {
    const res = await axios.get(`${requestUrl}`, { params: requestParams });
    return res?.data || {};
  };
  return useQuery(['banks-list'], fetchData, {
    refetchOnWindowFocus: false,
    onError,
  });
};

export const useCashesList = (onError?: any) => {
  const { user } = useAppSelector((state) => state.auth);
  const requestParams = { company_id: user?.company_id };
  const requestUrl = REACT_APP_API_URL + '/cashes/list';
  const fetchData = async () => {
    const res = await axios.get(`${requestUrl}`, { params: requestParams });
    return res?.data || {};
  };
  return useQuery(['cashes-list'], fetchData, {
    refetchOnWindowFocus: false,
    onError,
  });
};

export const useInstallmentList = (params: any, onError?: any) => {
  const { user } = useAppSelector((state) => state.auth);
  const requestParams = { company_id: user?.company_id, ...params };
  const requestUrl = REACT_APP_API_URL + '/payments/installment/list';
  const fetchData = async () => {
    const res = await axios.get(`${requestUrl}`, { params: requestParams });
    return res?.data || {};
  };
  return useQuery(['installments-list-new'], fetchData, {
    refetchOnWindowFocus: false,
    onError,
    enabled: false,
  });
};

export const useFinanceInvoiceUpload = (onSuccess?: any, onError?: any) => {
  const { user } = useAppSelector((state) => state.auth);
  const requestUrl = REACT_APP_API_URL + '/payments/invoice/upload';
  return useMutation(
    (formPayload: any) => {
      const formData: any = new FormData();
      formData.append('file', formPayload?.file[0]);
      formData.append('payment_id', formPayload?.payment_id);
      formData.append('company_id', user?.company_id);
      return axios.post(`${requestUrl}`, formData);
    },
    { onError, onSuccess },
  );
};

export const useFinanceInvoiceDelete = (onSuccess?: any, onError?: any) => {
  const { user } = useAppSelector((state) => state.auth);
  const requestUrl = REACT_APP_API_URL + '/payments/invoice/delete';
  return useMutation(
    (formPayload: any) => {
      return axios.post(`${requestUrl}`, { ...formPayload, company_id: user?.company_id });
    },
    { onError, onSuccess },
  );
};
