/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useRef } from 'react';
import CurrencyFormat from 'react-currency-format';
import { toast } from 'react-toastify';
import { ErrorMessage, Form, Formik, FormikProps } from 'formik';
import { KTSVG, toAbsoluteUrl } from 'src/_metronic/helpers';
import * as Yup from 'yup';
import { useInviteCreate } from '../service';

const InviteCompany: FC = () => {
  const formikRef = useRef<FormikProps<any>>(null);

  const onSuccessFetch = (e: any) => {
    toast.success('Davetiye başarıyla gönderildi.');
    formikRef.current?.resetForm();
  };

  const onErrorFetch = (e: any) =>
    (e?.response?.data?.message && toast.error(e?.response?.data?.message)) || toast.error('Bir sorun oluştu! Lütfen daha sonra tekrar deneyin.', { autoClose: 7000 });

  const { isLoading, mutate, reset } = useInviteCreate(onSuccessFetch, onErrorFetch);

  const inviteSchema = Yup.object().shape({
    company_name: Yup.string().min(1, 'Lütfen kurum adını belirtin.').required('Lütfen kurum adını belirtin.'),
    company_email: Yup.string().email('Lütfen geçerli bir e-posta adresi belirtin.').required('Lütfen kurum e-posta adresini belirtin.'),
    company_phone: Yup.object().shape({
      floatValue: Yup.string().min(1).required(),
      formattedValue: Yup.string().min(1).required(),
      value: Yup.string().min(10, 'Telefon numarasını 10 haneli belirtin.').max(10, 'Telefon numarasını 10 haneli belirtin.').required('Lütfen telefon numarasını belirtin.'),
    }),
    terms_and_condition: Yup.boolean().oneOf([true], 'Lütfen şartlar ve koşulları onaylayın.'),
  });
  var initialValues = {
    company_name: '',
    company_email: '',
    company_phone: {
      formattedValue: '',
      value: '',
      floatValue: 0,
    },
    terms_and_condition: false,
  };
  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={inviteSchema}
      onSubmit={(values, { setSubmitting }) => {
        const body = { ...values };
        body.company_phone = body?.company_phone?.value;
        mutate(body);
        setSubmitting(false);
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, getFieldProps, isSubmitting, isValid }: any) => (
        <form onSubmit={handleSubmit}>
          <div className="modal fade" id="kt_modal_invite_company" aria-hidden="true">
            <div className="modal-dialog mw-650px">
              <div className="modal-content">
                <div className="modal-header pb-0 border-0 justify-content-end">
                  <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                    <KTSVG path="/media/icons/duotone/Navigation/Close.svg" className="svg-icon-1" />
                  </div>
                </div>

                <div className="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15">
                  <div className="text-center mb-13">
                    <h1 className="mb-3">Eğitim Kurum'unu Davet Et</h1>

                    <div className="text-muted fw-bold fs-5">
                      Daha fazla bilgiye ihtiyacınız varsa lütfen
                      <span className="link-primary fw-bolder cursor-pointer" id="kt_terms_and_conditions_toggle" data-bs-dismiss="modal">
                        {' '}
                        Şartlar ve Koşullar{' '}
                      </span>
                      sayfasına göz atın.
                    </div>
                  </div>

                  <div className="separator d-flex flex-center mb-8">
                    <span className="text-uppercase bg-body fs-7 fw-bold text-muted px-3"></span>
                  </div>

                  <div className="mb-8">
                    <label className="form-label text-muted required">Kurum Adı</label>
                    <input className={`form-control form-control-solid ${errors.company_name && 'is-invalid border-danger'}`} placeholder="" {...getFieldProps('company_name')} />
                    <div className="fv-plugins-message-container invalid-feedback">
                      <ErrorMessage name="company_name" />
                    </div>
                  </div>

                  <div className="mb-8">
                    <label className="form-label text-muted required">Kurum E-Posta Adresi</label>
                    <input className={`form-control form-control-solid ${errors.company_email && 'is-invalid border-danger'}`} placeholder="" {...getFieldProps('company_email')} />
                    <div className="fv-plugins-message-container invalid-feedback">
                      <ErrorMessage name="company_email" />
                    </div>
                  </div>

                  <div className="mb-8">
                    <label className="form-label text-muted required">Kurum Telefon Numarası</label>
                    <CurrencyFormat
                      format="(###) ###-####"
                      mask="_"
                      name={'company_phone'}
                      value={values?.company_phone?.formattedValue}
                      onValueChange={(e) => formikRef.current?.setFieldValue('company_phone', e)}
                      className={`form-control form-control-solid ${errors.company_phone?.value && touched.company_phone?.value && 'is-invalid border-danger'}`}
                    />
                    <div className="fv-plugins-message-container invalid-feedback">
                      <ErrorMessage name="company_phone.value" component="div" className="error" />
                    </div>
                  </div>

                  <div className="d-flex flex-stack">
                    <div className="me-5 fw-bold">
                      <label className="fs-6">Şartlar ve Koşulları kabul ediyorum.</label>
                      <div className="fs-7 text-muted">Daha fazla bilgiye ihtiyacınız varsa lütfen şartlar ve koşullar'ı kontrol edin</div>
                    </div>

                    <label className="form-check form-switch form-check-custom form-check-solid">
                      <input className="form-check-input" type="checkbox" {...getFieldProps('terms_and_condition')} checked={values.terms_and_condition} id="terms_and_condition" />
                    </label>
                  </div>
                  <div className="fv-plugins-message-container invalid-feedback">
                    <ErrorMessage name="terms_and_condition" component="div" className="error" />
                  </div>
                </div>
                <div className="modal-footer mx-xl-18">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                    Kapat
                  </button>
                  <button type="submit" className="btn btn-light-success" disabled={!isValid}>
                    Davet Et
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export { InviteCompany };
