/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import ReactPaginate from 'react-paginate';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import { KTSVG } from 'src/_metronic/helpers';
import { MixedWidget1, MixedWidget2, MixedWidget3, MixedWidget4, MixedWidget6 } from 'src/_metronic/partials/widgets';
import { useControlGib } from 'src/app/modules/accounts/service';
import NotAllowed from 'src/app/permissions/not-allowed';
import Restricted from 'src/app/permissions/restricted';
import { PageTitle } from '../../../_metronic/layout/core';
import EArsivDraftApprove from '../finance/modals/e-arsiv-draft-approve';
import EArsivDraftDelete from '../finance/modals/e-arsiv-draft-delete';
import { useGibAuth, useGibDraftSendSMS, useGibHTML, useGibInvoices } from '../finance/service';
import { InviteCompany } from './_modals/invite-company';
import { BankChart } from './components/bank-chart';
import { CashChart } from './components/cash-chart';
import { TermsAndConditions } from './components/terms-and-conditions';
import { useDashboardList } from './service';

type Props = {
  dashboardList: any;
  history: any;
  notResultInvoice: any;
  draftInvoiceList: any;
  handleProcess: any;
  handleSendSMS: any;
  draftInvoiceApproveModal: any;
  setDraftInvoiceApproveModal: any;
  refetchGibInvoices: any;
  list: any;
  handleDeleteDraft: any;
  draftInvoiceDeleteModal: any;
  setDraftInvoiceDeleteModal: any;
};

const DashboardPage: FC<Props> = ({
  dashboardList,
  history,
  notResultInvoice,
  draftInvoiceList,
  handleProcess,
  handleSendSMS,
  draftInvoiceApproveModal,
  setDraftInvoiceApproveModal,
  refetchGibInvoices,
  list,
  handleDeleteDraft,
  draftInvoiceDeleteModal,
  setDraftInvoiceDeleteModal,
}) => {
  const [invitationModal, setInvitationModal] = useState<boolean>(false);
  const onErrorFetch = (e: any) => {};
  const { data: gibControlData, isLoading: gibControlIsLoading, isFetching: gibControlIsFetching } = useControlGib(onErrorFetch);

  return (
    <>
      <div className="row">
        <div className="col-xl-12 mb-5">
          <div className="card border-transparent bg-white ">
            {/*begin::Body*/}
            <div className="card-body d-flex ps-xl-15">
              {/*begin::Wrapper*/}
              <div className="m-0">
                {/*begin::Title*/}
                <div className="position-relative fs-2 z-index-2 fw-bolder text-gray-800 mb-7">
                  <span className="me-2">Davet Et ve Kazan !</span>
                  <br />
                  <div className="fw-normal fs-3">
                    Eduthon'lu olmaları için kurumları Eduthon'a davet edin,
                    <span className="position-relative d-inline-block text-danger mx-1 fw-bolder">
                      <a href="/" className="text-danger opacity-75-hover">
                        +3 Ay
                      </a>
                      {/*begin::Separator*/}
                      <span className="position-absolute opacity-50 bottom-0 start-0 border-4 border-danger border-bottom w-100" />
                      {/*end::Separator*/}
                    </span>
                    Eduthon aboneliği kazanın.
                  </div>
                </div>
                {/*end::Title*/}
                {/*begin::Action*/}
                <div className="mb-3">
                  <button className="btn btn-danger fw-semibold me-2" data-bs-toggle="modal" data-bs-target="#kt_modal_invite_company">
                    Davet Et
                  </button>
                  <div id="kt_terms_and_conditions_toggle" className="btn btn-secondary text-gray fw-semibold">
                    Şartlar ve Koşullar
                  </div>
                </div>
                {/*begin::Action*/}
              </div>
              {/*begin::Wrapper*/}
              {/*begin::Illustration*/}
              <img src="/media/illustrations/17-dark.png" className="position-absolute me-3 bottom-0 end-0 h-200px d-lg-block d-none" alt="" />
              {/*end::Illustration*/}
            </div>
            {/*end::Body*/}
          </div>
        </div>
      </div>
      <div className="row">
        {/* begin::Col */}
        <div className="col-xl-6 mb-5">
          <MixedWidget1 className="card-xl-stretch mb-xl-8" color="success bg-gradient" dashboardList={dashboardList?.monthlyFinance[0]} />
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className="col-xl-6 mb-5">
          <MixedWidget6 className="card-xl-stretch mb-xl-8" chartColor="primary" chartHeight="230px" dashboardList={dashboardList?.currentActivity} />
        </div>

        {/* end::Col */}
      </div>
      {gibControlData?.result?.data?.length > 0 && (
        <div className="row g-5 g-xl-10  mb-10">
          {/*begin::Col*/}
          <div className="col-xxl-12">
            {/*begin::Timeline widget 2*/}
            <div className="card h-xl-100" id="kt_timeline_widget_2_card">
              {/*begin::Header*/}
              {/* <div className="card-header position-relative py-0 border-bottom-2">buraya taslak faturalar başlığı gelir</div> */}
              <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bolder fs-3 mb-1">E-Arşiv Taslak Faturalar</span>
                  <span className="text-muted fw-bold fs-7">Henüz imzalanmamış ve imzalanmayı bekleyen e-arşiv faturalarınız.</span>
                </h3>
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              <div className="card-body">
                {/*begin::Tab Content*/}
                <div className="tab-content">
                  {/*begin::Tap pane*/}
                  <div className="tab-pane fade show active" id="kt_timeline_widget_2_tab_1" role="tabpanel">
                    {/*begin::Table container*/}
                    <div className="table-responsive">
                      {/* begin::Table */}
                      <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        {/* begin::Table head */}
                        <thead>
                          <tr className="fw-bolder text-muted">
                            <th className="min-w-100px">Döküman No.</th>
                            <th className="min-w-100px">Vergi No.</th>
                            <th className="min-w-100px">Kurum</th>
                            <th className="min-w-100px">Tarihi</th>
                            <th className="min-w-100px">Tipi</th>
                            <th className="min-w-100px">Durumu</th>
                            <th className="min-w-100px text-end">İşlemler</th>
                          </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                          {draftInvoiceList?.slice(0, 5).map((list: any, index: number) => (
                            <tr key={index}>
                              <td>{list?.documentNumber}</td>
                              <td>{list?.taxOrIdentityNumber}</td>
                              <td>{list?.buyerTitleOrFullName}</td>
                              <td>{list?.documentDate}</td>
                              <td>{list?.documentType}</td>
                              <td>{list?.approvalStatus}</td>
                              <td>
                                <div className="d-flex justify-content-end">
                                  <button className="btn btn-sm btn-light me-2 mb-2" onClick={() => handleProcess('html', list)}>
                                    <KTSVG path="/media/icons/duotone/General/Binocular.svg" className="svg-icon-1x svg-icon-gray-500 text-gray-500" />
                                  </button>
                                  <Restricted to="ROLE_FINANCE_PAYMENT_GIB_DELETE">
                                    <button className="btn btn-sm btn-light-danger me-2 mb-2" onClick={() => handleDeleteDraft(list)}>
                                      Taslağı Sil
                                    </button>
                                  </Restricted>
                                  <Restricted to="ROLE_FINANCE_PAYMENT_GIB_EDIT">
                                    <button
                                      className="btn btn-sm btn-light-success me-2 mb-2"
                                      onClick={() => {
                                        handleSendSMS(list);
                                      }}
                                    >
                                      İmzala
                                    </button>
                                  </Restricted>
                                </div>
                              </td>
                            </tr>
                          ))}
                          {notResultInvoice}
                        </tbody>
                        {/* end::Table body */}
                      </table>
                      {/* end::Table */}
                    </div>
                    {/*end::Table*/}
                  </div>
                  {/*end::Tap pane*/}
                </div>
                {/*end::Tab Content*/}
              </div>
              {/*end::Body*/}
            </div>
            {/*end::Tables Widget 2*/}
          </div>
          {/*end::Col*/}
        </div>
      )}

      <div className="row mb-10">
        <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10">
          <div
            className="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-md-50 mb-5 mb-xl-10"
            style={{
              backgroundColor: 'rgb(241, 65, 108)',
              backgroundImage: 'url("/media/patterns/vector-1.png")',
            }}
          >
            <div className="card-header pt-5">
              <div className="card-title d-flex flex-column">
                <span className="fs-2hx fw-bold text-white me-2 lh-1 ls-n2">{dashboardList?.registration_information?.certain_registration ?? 0}</span>
                <span className="text-white opacity-75 pt-1 fw-semibold fs-6">Kesin Kayıt</span>
              </div>
            </div>
            <div className="card-body d-flex align-items-end pt-0">
              <div className="d-flex align-items-center flex-column mt-3 w-100">
                <div className="d-flex justify-content-between fw-bold fs-6 text-white opacity-75 w-100 mt-auto mb-2">
                  <span>{dashboardList?.registration_information?.pre_registration} Ön Kayıt</span>
                  <span>
                    {dashboardList?.registration_information?.certain_registration
                      ? dashboardList?.registration_information?.certain_registration !== 0
                        ? `${
                            100 -
                            Number(
                              (
                                (Number(dashboardList?.registration_information?.pre_registration) * 100) /
                                Number(dashboardList?.registration_information?.certain_registration)
                              ).toFixed(1),
                            )
                          }%`
                        : 'N/A'
                      : `0%`}
                  </span>
                </div>
                <div className="h-8px mx-3 w-100 bg-white bg-opacity-50 rounded">
                  <div
                    className="bg-white rounded h-8px"
                    role="progressbar"
                    aria-valuenow={50}
                    aria-valuemin={0}
                    aria-valuemax={100}
                    style={{
                      width: `${(
                        100 -
                        (Number(dashboardList?.registration_information?.pre_registration) * 100) / Number(dashboardList?.registration_information?.certain_registration)
                      ).toFixed(1)}%`,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="card card-flush h-md-50 mb-5 mb-xl-10">
            <div className="card-header pt-5">
              <div className="card-title d-flex flex-column">
                <div className="card-title d-flex flex-column">
                  <span className="fs-2hx fw-bold text-gray-900 me-2 lh-1 ls-n2">{dashboardList?.registration_information?.active_users ?? 0}</span>
                  <span className="text-gray-500 pt-1 fw-semibold fs-6">Öğrenci Hesabı</span>
                </div>
              </div>
            </div>
            <div className="card-body d-flex flex-column justify-content-end pe-0">
              <span className="fs-6 fw-bolder text-gray-800 d-block mb-2">Son Aktif Kullanıcılar</span>
              <div className="symbol-group symbol-hover flex-nowrap">
                {dashboardList?.registration_information?.last_active_users?.map((user: any, idx: number) => (
                  <div className="symbol symbol-35px symbol-circle" key={idx}>
                    <span className={`symbol-label fw-bold ${idx % 2 === 0 ? 'bg-warning' : 'bg-primary'} ${idx % 3 === 0 ? 'bg-danger' : 'bg-info'}  text-inverse-warning`}>
                      {user?.name?.[0]}
                    </span>
                  </div>
                ))}
                {dashboardList?.registration_information?.last_active_users?.length > 5 && (
                  <a href="#" className="symbol symbol-35px symbol-circle">
                    <span className="symbol-label fs-8 fw-bold bg-dark text-gray-300">
                      +{Number(dashboardList?.registration_information?.active_users) - dashboardList?.registration_information?.last_active_users?.length}
                    </span>
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-9">
          <CashChart className="" chartData={dashboardList?.cash} />
        </div>
      </div>
      <div className="row mb-10">
        <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10">
          {/*begin::Row*/}
          <div className="row g-5 g-xl-10 h-100 mb-5">
            {/*begin::Col*/}
            <div className="col-md-12 col-xl-12 h-50">
              {/*begin::Card widget 8*/}
              <div id="kt_sliders_widget_3_slider" className="card card-flush carousel slide h-100" data-bs-ride="carousel" data-bs-interval={3000}>
                {/*begin::Header*/}
                <div className="card-header pt-5 mb-5">
                  {/*begin::Title*/}
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder text-gray-900">Kasa Bilgileri</span>
                    {/* <span className="text-gray-500 mt-1 fw-semibold fs-7">Avg. 72% completed lessons</span> */}
                  </h3>
                  {/*end::Title*/}
                  {/*begin::Toolbar*/}
                  <div className="card-toolbar">
                    <div className="d-flex justify-content-end">
                      <a
                        href="#kt_sliders_widget_3_slider"
                        className="carousel-control-prev position-relative me-1 active bg-secondary px-3 py-1 rounded"
                        role="button"
                        data-bs-slide="prev"
                      >
                        <KTSVG path="/media/icons/duotone/Navigation/Angle-left.svg" className="svg-icon-1x svg-icon-gray-500 text-gray-500" />
                      </a>
                      <a href="#kt_sliders_widget_3_slider" className="carousel-control-next position-relative bg-secondary px-3 py-1 rounded" role="button" data-bs-slide="next">
                        <KTSVG path="/media/icons/duotone/Navigation/Angle-right.svg" className="svg-icon-1x svg-icon-gray-500 text-gray-500" />
                      </a>
                    </div>
                  </div>
                  {/*end::Toolbar*/}
                </div>
                {/*end::Header*/}
                {/*begin::Body*/}
                <div className="card-body p-0">
                  {/*begin::Carousel*/}
                  <div className="carousel-inner">
                    {/*begin::Item*/}
                    {dashboardList?.income_expenses?.length > 0 ? (
                      dashboardList?.income_expenses?.length === 1 ? (
                        <div className={``}>
                          {/*begin::Info*/}
                          <div className="d-flex align-items-center w-100 px-8">
                            {/*begin::Currency*/}
                            <span className="fs-4 fw-semibold text-gray-500 me-1 align-self-start">TL</span>
                            {/*end::Currency*/}
                            {/*begin::Amount*/}
                            <span className="fs-2hx fw-bold text-gray-900 me-2 lh-1 ls-n2">{dashboardList?.income_expenses[0]?.bakiye?.toLocaleString('tr-TR')}</span>
                            {/*end::Amount*/}
                            {/*begin::Badge*/}
                            <span className={`badge ${dashboardList?.income_expenses[0]?.bakiye > 0 ? 'badge-light-success' : 'badge-light-danger'} fs-base`}>
                              <i className="ki-duotone ki-arrow-up fs-5 text-success ms-n1">
                                <span className="path1" />
                                <span className="path2" />
                              </i>
                              {dashboardList?.income_expenses[0]?.bakiye > 0 ? (
                                <KTSVG path="/media/icons/duotone/Navigation/Angle-up.svg" className="svg-icon-1x svg-icon-gray-800" />
                              ) : (
                                <KTSVG path="/media/icons/duotone/Navigation/Angle-down.svg" className="svg-icon-1x svg-icon-gray-800" />
                              )}
                            </span>
                            {/*end::Badge*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Subtitle*/}
                          <div className="mt-5">
                            <div className="text-gray-700 pt-1 fw-semibold fs-6 px-8">{dashboardList?.income_expenses[0]?.name}</div>
                            <div className="text-gray-500 pt-1 fw-bold fs-6 px-8">{dashboardList?.income_expenses[0]?.description}</div>
                          </div>

                          {/*end::Subtitle*/}
                        </div>
                      ) : (
                        dashboardList?.income_expenses?.map((cash: any, idx: number) => (
                          <>
                            <div className={`carousel-item   ${idx === 0 ? 'show carousel-item-next active' : ''}  carousel-item-start`} key={idx}>
                              {/*begin::Info*/}
                              <div className="d-flex align-items-center w-100 px-8">
                                {/*begin::Currency*/}
                                <span className="fs-4 fw-semibold text-gray-500 me-1 align-self-start">TL</span>
                                {/*end::Currency*/}
                                {/*begin::Amount*/}
                                <span className="fs-2hx fw-bold text-gray-900 me-2 lh-1 ls-n2">{cash?.bakiye?.toLocaleString('tr-TR')}</span>
                                {/*end::Amount*/}
                                {/*begin::Badge*/}
                                <span className={`badge ${cash?.bakiye > 0 ? 'badge-light-success' : 'badge-light-danger'} fs-base`}>
                                  <i className="ki-duotone ki-arrow-up fs-5 text-success ms-n1">
                                    <span className="path1" />
                                    <span className="path2" />
                                  </i>
                                  {cash?.bakiye > 0 ? (
                                    <KTSVG path="/media/icons/duotone/Navigation/Angle-up.svg" className="svg-icon-1x svg-icon-gray-800" />
                                  ) : (
                                    <KTSVG path="/media/icons/duotone/Navigation/Angle-down.svg" className="svg-icon-1x svg-icon-gray-800" />
                                  )}
                                </span>
                                {/*end::Badge*/}
                              </div>
                              {/*end::Info*/}
                              {/*begin::Subtitle*/}
                              <div className="mt-5">
                                <div className="text-gray-700 pt-1 fw-semibold fs-6 px-8">{cash?.name}</div>
                                <div className="text-gray-500 pt-1 fw-bold fs-6 px-8">{cash?.description}</div>
                              </div>

                              {/*end::Subtitle*/}
                            </div>
                          </>
                        ))
                      )
                    ) : (
                      <div className="px-8">Kasa bilgisi bulunmuyor.</div>
                    )}

                    {/*end::Item*/}
                  </div>
                  {/*end::Carousel*/}
                </div>
                {/*end::Body*/}
              </div>
            </div>
            <div className="col-md-12 col-xl-12 h-50">
              {/*begin::Card widget 8*/}
              <div id="kt_sliders_widget_2_slider" className="card card-flush carousel slide h-100" data-bs-ride="carousel" data-bs-interval={2000}>
                {/*begin::Header*/}
                <div className="card-header pt-5 mb-5">
                  {/*begin::Title*/}
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder text-gray-900">Banka Bilgileri</span>
                    {/* <span className="text-gray-500 mt-1 fw-semibold fs-7">Avg. 72% completed lessons</span> */}
                  </h3>
                  {/*end::Title*/}
                  {/*begin::Toolbar*/}
                  <div className="card-toolbar">
                    <div className="d-flex justify-content-end">
                      <a
                        href="#kt_sliders_widget_2_slider"
                        className="carousel-control-prev position-relative me-1 active bg-secondary px-3 py-1 rounded"
                        role="button"
                        data-bs-slide="prev"
                      >
                        <KTSVG path="/media/icons/duotone/Navigation/Angle-left.svg" className="svg-icon-1x svg-icon-gray-500 text-gray-500" />
                      </a>
                      <a href="#kt_sliders_widget_2_slider" className="carousel-control-next position-relative bg-secondary px-3 py-1 rounded" role="button" data-bs-slide="next">
                        <KTSVG path="/media/icons/duotone/Navigation/Angle-right.svg" className="svg-icon-1x svg-icon-gray-500 text-gray-500" />
                      </a>
                    </div>
                  </div>
                  {/*end::Toolbar*/}
                </div>
                {/*end::Header*/}
                {/*begin::Body*/}
                <div className="card-body p-0">
                  {/*begin::Carousel*/}
                  <div className="carousel-inner">
                    {/*begin::Item*/}
                    {dashboardList?.bank_information?.length > 0 ? (
                      dashboardList?.bank_information?.length === 1 ? (
                        <div className={``}>
                          {/*begin::Info*/}
                          <div className="d-flex align-items-center w-100 px-8">
                            {/*begin::Currency*/}
                            <span className="fs-4 fw-semibold text-gray-500 me-1 align-self-start">TL</span>
                            {/*end::Currency*/}
                            {/*begin::Amount*/}
                            <span className="fs-2hx fw-bold text-gray-900 me-2 lh-1 ls-n2">{dashboardList?.bank_information?.[0]?.bakiye?.toLocaleString('tr-TR')}</span>
                            {/*end::Amount*/}
                            {/*begin::Badge*/}
                            <span className={`badge ${dashboardList?.bank_information?.[0]?.bakiye > 0 ? 'badge-light-success' : 'badge-light-danger'} fs-base`}>
                              <i className="ki-duotone ki-arrow-up fs-5 text-success ms-n1">
                                <span className="path1" />
                                <span className="path2" />
                              </i>
                              {dashboardList?.bank_information?.[0]?.bakiye > 0 ? (
                                <KTSVG path="/media/icons/duotone/Navigation/Angle-up.svg" className="svg-icon-1x svg-icon-gray-800" />
                              ) : (
                                <KTSVG path="/media/icons/duotone/Navigation/Angle-down.svg" className="svg-icon-1x svg-icon-gray-800" />
                              )}
                            </span>
                            {/*end::Badge*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Subtitle*/}
                          <div className="mt-5">
                            <div className="text-gray-700 pt-1 fw-semibold fs-6 px-8">{dashboardList?.bank_information?.[0]?.account_name}</div>
                            <div className="text-gray-500 pt-1 fw-bold fs-6 px-8">{dashboardList?.bank_information?.[0]?.account_no}</div>
                          </div>

                          {/*end::Subtitle*/}
                        </div>
                      ) : (
                        dashboardList?.bank_information?.map((bank: any, idx: number) => (
                          <div className={`carousel-item   ${idx === 0 ? 'show carousel-item-next active' : ''}  carousel-item-start`} key={idx}>
                            {/*begin::Info*/}
                            <div className="d-flex align-items-center w-100 px-8">
                              {/*begin::Currency*/}
                              <span className="fs-4 fw-semibold text-gray-500 me-1 align-self-start">TL</span>
                              {/*end::Currency*/}
                              {/*begin::Amount*/}
                              <span className="fs-2hx fw-bold text-gray-900 me-2 lh-1 ls-n2">{bank?.bakiye?.toLocaleString('tr-TR')}</span>
                              {/*end::Amount*/}
                              {/*begin::Badge*/}
                              <span className={`badge ${bank?.bakiye > 0 ? 'badge-light-success' : 'badge-light-danger'} fs-base`}>
                                <i className="ki-duotone ki-arrow-up fs-5 text-success ms-n1">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                                {bank?.bakiye > 0 ? (
                                  <KTSVG path="/media/icons/duotone/Navigation/Angle-up.svg" className="svg-icon-1x svg-icon-gray-800" />
                                ) : (
                                  <KTSVG path="/media/icons/duotone/Navigation/Angle-down.svg" className="svg-icon-1x svg-icon-gray-800" />
                                )}
                              </span>
                              {/*end::Badge*/}
                            </div>
                            {/*end::Info*/}
                            {/*begin::Subtitle*/}
                            <div className="mt-5">
                              <div className="text-gray-700 pt-1 fw-semibold fs-6 px-8">{bank?.account_name}</div>
                              <div className="text-gray-500 pt-1 fw-bold fs-6 px-8">{bank?.account_no}</div>
                            </div>

                            {/*end::Subtitle*/}
                          </div>
                        ))
                      )
                    ) : (
                      <div className="px-8">Banka bilgisi bulunmuyor.</div>
                    )}

                    {/*end::Item*/}
                  </div>
                  {/*end::Carousel*/}
                </div>
                {/*end::Body*/}
              </div>
            </div>
            {/*end::Col*/}
          </div>

          {/*end::Row*/}
        </div>

        <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-9">
          <BankChart className="" chartData={dashboardList?.bank} />
        </div>
      </div>
      <div className="row g-5 g-xl-10">
        {/*begin::Col*/}
        <div className="col-xxl-8">
          {/*begin::Timeline widget 2*/}
          <div className="card h-xl-100" id="kt_timeline_widget_2_card">
            {/*begin::Header*/}
            <div className="card-header position-relative py-0 border-bottom-2">
              {/*begin::Nav*/}
              <ul className="nav nav-pills d-flex mt-4" role="tablist">
                {/*begin::Item*/}
                <li className="nav-item p-0 ms-0 me-8" role="presentation">
                  {/*begin::Link*/}
                  <a
                    className="nav-link px-5 btn btn-active-light-primary btn-color-muted active px-0"
                    data-bs-toggle="pill"
                    href="#kt_timeline_widget_2_tab_1"
                    aria-selected="true"
                    role="tab"
                  >
                    {/*begin::Subtitle*/}
                    <span className="nav-text fw-semibold fs-6 mb-3">Geçmiş Ödevler</span>
                    {/*end::Subtitle*/}
                  </a>
                  {/*end::Link*/}
                </li>
                {/*end::Item*/}
                {/*begin::Item*/}
                <li className="nav-item p-0 ms-0 me-8" role="presentation">
                  {/*begin::Link*/}
                  <a
                    className="nav-link px-5 btn btn-active-light-primary btn-color-muted px-0"
                    data-bs-toggle="pill"
                    href="#kt_timeline_widget_2_tab_2"
                    aria-selected="false"
                    tabIndex={-1}
                    role="tab"
                  >
                    {/*begin::Subtitle*/}
                    <span className="nav-text fw-semibold fs-6 mb-3">Aktif Ödevler</span>
                    {/*end::Subtitle*/}
                    {/*begin::Bullet*/}
                    {/*end::Bullet*/}
                  </a>
                  {/*end::Link*/}
                </li>
                {/*end::Item*/}
              </ul>
              {/*end::Nav*/}
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className="card-body">
              {/*begin::Tab Content*/}
              <div className="tab-content">
                {/*begin::Tap pane*/}
                <div className="tab-pane fade show active" id="kt_timeline_widget_2_tab_1" role="tabpanel">
                  {/*begin::Table container*/}
                  <div className="table-responsive">
                    {/*begin::Table*/}
                    <table className="table align-middle gs-0 gy-4">
                      {/*begin::Table head*/}
                      <thead>
                        <tr>
                          <th className="p-0 w-10px" />
                          <th className="p-0 min-w-400px" />
                          <th className="p-0 min-w-100px" />
                        </tr>
                      </thead>
                      {/*end::Table head*/}
                      {/*begin::Table body*/}
                      <tbody>
                        {dashboardList?.homeworksPast?.length > 0 ? (
                          dashboardList?.homeworksPast?.map((past: any, idx: number) => (
                            <tr key={idx}>
                              <td>
                                <span data-kt-element="bullet" className="bullet bullet-vertical d-flex align-items-center h-40px bg-secondary" />
                              </td>
                              <td>
                                <a onClick={() => history.push(`/homework/detail/${past?.id}`)} className="text-gray-800 text-hover-primary fw-bold fs-6 cursor-pointer">
                                  {past?.classes?.name} - {past?.classes_branch?.name}
                                </a>
                                <span className="text-gray-500 fw-bold fs-7 d-block">{past?.lessons?.name}</span>
                              </td>
                              <td className="text-end">
                                <span data-kt-element="status" className="badge badge-light-danger">
                                  Bitti
                                </span>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={3} className="text-center text-gray-700">
                              Ödeviniz bulunmuyor.
                            </td>
                          </tr>
                        )}
                      </tbody>
                      {/*end::Table body*/}
                    </table>
                  </div>
                  {/*end::Table*/}
                </div>
                {/*end::Tap pane*/}
                {/*begin::Tap pane*/}
                <div className="tab-pane fade " id="kt_timeline_widget_2_tab_2" role="tabpanel">
                  {/*begin::Table container*/}
                  <div className="table-responsive">
                    {/*begin::Table*/}
                    <table className="table align-middle gs-0 gy-4">
                      {/*begin::Table head*/}
                      <thead>
                        <tr>
                          <th className="p-0 w-10px" />
                          <th className="p-0 min-w-400px" />
                          <th className="p-0 min-w-100px" />
                        </tr>
                      </thead>
                      {/*end::Table head*/}
                      {/*begin::Table body*/}
                      <tbody>
                        {dashboardList?.homeworksUpComing?.length > 0 ? (
                          dashboardList?.homeworksUpComing?.map((past: any, idx: number) => (
                            <tr key={idx}>
                              <td>
                                <span data-kt-element="bullet" className="bullet bullet-vertical d-flex align-items-center h-40px bg-secondary" />
                              </td>
                              <td>
                                <a onClick={() => history.push(`/homework/detail/${past?.id}`)} className="text-gray-800 text-hover-primary fw-bold fs-6 cursor-pointer">
                                  {past?.classes?.name} - {past?.classes_branch?.name}
                                </a>
                                <span className="text-gray-500 fw-bold fs-7 d-block">{past?.lessons?.name}</span>
                              </td>
                              <td className="text-end">
                                <span data-kt-element="status" className="badge badge-light-success">
                                  Devam Ediyor
                                </span>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={3} className="text-center text-gray-700">
                              Aktif Ödeviniz bulunmuyor.
                            </td>
                          </tr>
                        )}
                      </tbody>
                      {/*end::Table body*/}
                    </table>
                  </div>
                  {/*end::Table*/}
                </div>
                {/*end::Tap pane*/}
              </div>
              {/*end::Tab Content*/}
            </div>
            {/*end::Body*/}
          </div>
          {/*end::Tables Widget 2*/}
        </div>
        {/*end::Col*/}
        {/*begin::Col*/}
        <div className="col-xxl-4">
          {/*begin::List widget 20*/}
          <div className="card h-xl-100">
            {/*begin::Header*/}
            <div className="card-header border-0 pt-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-gray-900">Dersler</span>
                <span className="text-muted mt-1 fw-semibold fs-7">{dashboardList?.allLessons?.length} adet ders mevcut</span>
              </h3>
              {/*begin::Toolbar*/}
              <div className="card-toolbar">
                <Link to={'/lessons/management'} className="btn btn-sm btn-light">
                  Tüm Dersler
                </Link>
              </div>
              {/*end::Toolbar*/}
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className="card-body pt-6">
              {/*begin::Item*/}
              {dashboardList?.allLessons?.map((lesson: any, idx: number) => (
                <div className="d-flex flex-stack mb-5" key={idx}>
                  {/*begin::Symbol*/}
                  <div className="symbol symbol-40px me-4">
                    <div className="symbol-label fs-2 fw-semibold bg-danger text-inverse-danger">{lesson?.name?.[0]}</div>
                  </div>
                  {/*end::Symbol*/}
                  {/*begin::Section*/}
                  <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                    {/*begin:Author*/}
                    <div className="flex-grow-1 me-2">
                      <div className="text-gray-800 text-hover-primary fs-6 fw-bold">{lesson?.name}</div>
                      <span className="text-muted fw-semibold d-block fs-7">{lesson?.shortcode}</span>
                    </div>
                    {/*end:Author*/}
                    {/*begin::Actions*/}

                    {/*begin::Actions*/}
                  </div>
                  {/*end::Section*/}
                </div>
              ))}
            </div>
            {/*end::Body*/}
          </div>
          {/*end::List widget 20*/}
        </div>
        {/*end::Col*/}
      </div>
      <EArsivDraftDelete show={draftInvoiceDeleteModal} handleClose={() => setDraftInvoiceDeleteModal(false)} refetchList={refetchGibInvoices} list={list} />
      <EArsivDraftApprove show={draftInvoiceApproveModal} handleClose={() => setDraftInvoiceApproveModal(false)} refetchList={refetchGibInvoices} list={list} />
    </>
  );
};

const DashboardWrapper: FC = () => {
  const intl = useIntl();
  const onErrorFetch = (e: any) => e?.response?.data?.message && toast.error(e?.response?.data?.message);
  const onErrorFetchEmpty = (e: any) => {};
  const [filters, setFilters] = useState<any>({});
  const [filtersGib, setFiltersGib] = useState<any>({ approvalStatus: '0' });
  const [sign, setSign] = React.useState(false);

  const { refetch, data: dashboardList, isLoading, isFetching } = useDashboardList({ ...filters }, onErrorFetch);
  const {
    refetch: gibInvoicesRefetch,
    data: gibInvoicesData,
    isLoading: gibInvoicesIsLoading,
    isFetching: gibInvoicesIsFetching,
  } = useGibInvoices({ ...filtersGib }, onErrorFetchEmpty);
  const { refetch: gibAuthRefetch, data: gibAuthData, isLoading: gibAuthIsLoading, isFetching: gibAuthIsFetching } = useGibAuth(onErrorFetchEmpty);
  const onSuccessFetchHTML = (e: any) => {
    // toast.success(`Belge oluşturuldu.`);
  };
  const { isLoading: loadingHTML, mutate: mutateHTML, data: dataHTML, reset: resetHTML } = useGibHTML(onSuccessFetchHTML, onErrorFetchEmpty);

  const triggerPaginationHomeworkPast = (event: any) => {
    setFilters((prevState: any) => ({
      ...prevState,
      pageHomeworkPast: event.selected + 1,
    }));
  };
  useEffect(() => {
    refetch();
  }, [filters.pageHomeworkPast, filters.limitHomeworkPast, filters.sortByHomeworkPast]);
  let history = useHistory();

  const getSessionData = () => {
    //fetching username from sesstion storage
    return sessionStorage.getItem('credentials') as string;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response: any = await gibAuthRefetch();
        const data = response?.data; // veya response.data şeklinde verilere ulaşabilirsiniz
        sessionStorage.setItem('credentials', JSON.stringify(data ? data : ''));
      } catch (error) {
        console.error('Error fetching data:', error);
        // Hata durumunda yapılması gereken işlemleri buraya ekleyebilirsiniz.
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const parseCredentials = JSON.parse(getSessionData());
    setFiltersGib({ ...filtersGib, ...parseCredentials, startDate: moment().subtract(1, 'months').format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD') });
    setTimeout(() => {
      gibInvoicesRefetch();
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const notResultInvoice = useMemo(
    () =>
      !gibInvoicesIsFetching &&
      !gibInvoicesIsLoading &&
      !gibInvoicesData?.length && (
        <tr className="text-center">
          <td colSpan={7}>
            <KTSVG path="/media/icons/duotone/Text/Filter.svg" className="svg-icon-2 svg-icon-muted mx-3" />
            Herhangi taslak e-arşiv fatura bulunamadı.
          </td>
        </tr>
      ),
    [gibInvoicesData, gibInvoicesIsFetching, gibInvoicesIsLoading],
  );

  const handleProcess = async (value: any, list: any) => {
    mutateHTML({
      username: filtersGib.username,
      password: filtersGib.password,
      invoiceOrUuid: list?.uuid,
      approvalStatus: list?.approvalStatus === 0,
      print: false,
    });
  };

  const initHTML = async () => {
    // Yeni bir sekme açmak için bir HTML belgesi oluşturun ve içeriği ekleyin
    const newTab: any = window.open();
    newTab.document.open();
    newTab.document.write(dataHTML?.data);
    newTab.document.close();
    resetHTML();
  };
  useEffect(() => {
    if (dataHTML) initHTML();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataHTML]);

  const [list, setList] = useState<any>();
  const [draftInvoiceApproveModal, setDraftInvoiceApproveModal] = useState<boolean>(false);
  const [draftInvoiceDeleteModal, setDraftInvoiceDeleteModal] = useState<boolean>(false);

  const onSuccessSendSMSFetch = (e: any) => {
    setList((prevState: any) => ({
      ...prevState,
      operationId: e?.data,
    }));
    setDraftInvoiceApproveModal(true);
    toast.success(`İmzalama için sms gönderildi.`);
  };
  const onErrorSendSMSFetch = (e: any) => {
    e?.response?.data?.message && toast.error(e?.response?.data?.message);
  };
  const { isLoading: loadingSendSMS, mutate: mutateSendSMS, data: dataSendSMS, reset: resetSendSMS }: any = useGibDraftSendSMS(onSuccessSendSMSFetch, onErrorSendSMSFetch);
  const handleSendSMS = (list: any) => {
    const parseCredentials = JSON.parse(getSessionData());
    setList({ ...list, ...parseCredentials });
    mutateSendSMS({ ...parseCredentials });
  };

  const handleDeleteDraft = (list: any) => {
    const parseCredentials = JSON.parse(getSessionData());
    setList({ ...list, ...parseCredentials });
    setDraftInvoiceDeleteModal(true);
  };

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <Restricted to="ROLE_DASHBOARD" fallback={<NotAllowed />}>
        <DashboardPage
          dashboardList={dashboardList}
          history={history}
          notResultInvoice={notResultInvoice}
          draftInvoiceList={gibInvoicesData}
          handleProcess={handleProcess}
          handleSendSMS={handleSendSMS}
          draftInvoiceApproveModal={draftInvoiceApproveModal}
          setDraftInvoiceApproveModal={setDraftInvoiceApproveModal}
          refetchGibInvoices={gibInvoicesRefetch}
          list={list}
          handleDeleteDraft={handleDeleteDraft}
          draftInvoiceDeleteModal={draftInvoiceDeleteModal}
          setDraftInvoiceDeleteModal={setDraftInvoiceDeleteModal}
        />
        <TermsAndConditions />
        <InviteCompany />
      </Restricted>
    </>
  );
};

export { DashboardWrapper };
