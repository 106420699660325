/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { KTSVG } from 'src/_metronic/helpers';

const Step5: FC = () => {
  return (
    <div className="w-100">
      <div className="pb-8 pb-lg-10">
        <h2 className="fw-bolder text-dark">İşleminiz Tamamlandı!</h2>
      </div>

      <div className="mb-0">
        <div className="fs-6 text-gray-600 mb-5">
          Eduthon ailesine hoş geldiniz! Eğitim kurumları için sunduğumuz yenilikçi çözümlerle beraber, öğrenme deneyimini daha etkili, keyifli ve verimli hale getirmek için
          buradayız. Aramıza katıldığınız için teşekkür eder, beraber harika işlere imza atmayı dört gözle bekleriz!
        </div>
      </div>
    </div>
  );
};

export { Step5 };
