import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap-v5';
// @ts-ignore
import TagsInput from 'react-tagsinput';
import { toast } from 'react-toastify';
import { Formik, FormikProps, FormikValues } from 'formik';
import { KTSVG } from 'src/_metronic/helpers';
import { _setStamp } from 'src/services/redux/slices/auth/auth-slice';
import * as Yup from 'yup';
import { useGibDraftApprove } from '../service';

type Props = {
    show: boolean;
    handleClose: Function;
    refetchList: any;
    list: any;
};

export default function EArsivDraftApprove({ show, handleClose, refetchList, list }: Props) {
    const formikRef = useRef<FormikProps<any>>(null);
    const onSuccessFetch = (e: any) => {
        toast.success(`Fatura başarıyla imzalandı.`);
        refetchList();
        dissmisLocation();
    };
    const onErrorFetch = (e: any) => e?.response?.data?.message && toast.error(e?.response?.data?.message);
    const { isLoading, mutate, reset } = useGibDraftApprove(onSuccessFetch, onErrorFetch);

    const dissmisLocation = () => {
        formikRef?.current?.resetForm();
        handleClose();
    };
    return (
        <Modal show={show} onHide={dissmisLocation} centered>
            <Formik
                innerRef={formikRef}
                initialValues={{ username: list?.username, password: list?.password, sms_code: '', operationId: list?.operationId, invoiceToBeSigned: { ...list } }}
                onSubmit={(values, { setSubmitting }) => {
                    if (values?.sms_code?.length > 0) mutate(values)
                    else toast.error(`Lütfen sms kodunu belirtin.`);
                    setSubmitting(false);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                    getFieldProps
                    /* and other goodies */
                }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="staticBackdropLabel">
                                    Taslak Fatura İmzala
                                </h5>
                                <button type="button" className="btn-close" onClick={dissmisLocation} aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="alert alert-secondary d-flex align-items-center p-5 mb-10">
                                    <span className="svg-icon svg-icon-2hx svg-icon-primary me-3">
                                        <KTSVG path="/media/icons/duotone/Home/Circle-info-solid.svg" className="svg-icon-primary text-primary me-5  " />
                                    </span>

                                    <div className="d-flex flex-column">
                                        <span className="fs-7">
                                            <b>{list?.documentNumber} </b> döküman numaralı taslak fatura'nın imzalanabilmesi için gönderilen sms kodunu girin
                                        </span>
                                    </div>
                                </div>
                                <div className="row mb-5">
                                    <div className="col-md-12">
                                        <label className="form-label required">SMS Kodu</label>
                                        <input type='text' className={`form-control form-control-solid ${errors.sms_code && touched.sms_code && 'is-invalid border-danger'}`} {...getFieldProps('sms_code')} />
                                        {errors.sms_code && touched.sms_code && <span className="text-danger">{errors.sms_code}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={dissmisLocation}>
                                    Vazgeç
                                </button>
                                <button type="button" className="btn btn-light-success" disabled={isSubmitting} onClick={() => formikRef?.current?.handleSubmit()}>
                                    İmzala
                                    {isLoading && <span className="spinner-border spinner-border-sm align-middle mx-2 text-white"></span>}
                                </button>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </Modal>
    );
}
