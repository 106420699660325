import { _logOut } from 'src/services/redux/slices/auth/auth-slice';

export default function setupAxios(axios: any, store: any) {
  axios.interceptors.request.use(
    (config: any) => {
      const {
        auth: { tokens, user },
      } = store.getState();
      if (tokens) {
        config.headers.Authorization = `Bearer ${tokens?.access?.token}`;
      }

      return config;
    },
    (err: any) => Promise.reject(err),
  );
  axios.interceptors.response.use(
    (response: any) => {
      return response;
    },
    async (error: any) => {
      const config = error?.config;
      if ((error?.response?.status === 401 || error?.response?.status === 503) && !config?.sent) {
        config.sent = true;

        await store.dispatch(_logOut());
        setTimeout(() => {
          window.location?.reload();
        }, 500);

        return axios(config);
      }
      return Promise.reject(error);
    },
  );
}
