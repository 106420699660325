import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import store from '../../app/store';

const REACT_APP_API_URL = `${process.env.REACT_APP_API_URL}`;

export interface ILogs {
  results: Array<{
    id: number;
    log_type_id: number;
    user_id: number;
    created_at: string;
    updated_at: string;
    content: string;
    documents: null | any;
    company_id: number;
    is_read: boolean;
    log_type: {
      id: number;
      log_type_name: string;
      created_at: string;
      updated_at: string;
    };
    user: {
      id: number;
      company_id: number;
      email: string;
      pass: string;
      status: null | any;
      authorization: number;
      pass_reset_token: string | null;
      notification_token: null | string;
      last_login_at: string;
      created_at: string;
      updated_at: string;
      name: string;
      registration_id: null | number;
      surname: string;
      profile_image: null | string;
    };
  }>;
  page: number;
  limit: number;
  totalResults: number;
  totalPages: number;
}

const initialState: IAuthState = {
  logs: undefined,
};

export interface IAuthState {
  logs?: ILogs | null;
}
export const logsList = createAsyncThunk('logs/list', async (params: any) => {
  const company_id = store.getState()?.auth?.user?.company?.id;
  params = { ...params, company_id };
  const res = await axios.get<any>(`${REACT_APP_API_URL}/logs/list`, { params });
  return res.data;
});

export const logsRead = createAsyncThunk('logs/read', async () => {
  const company_id = store.getState()?.auth?.user?.company?.id;
  const res = await axios.post<any>(`${REACT_APP_API_URL}/logs/read`, { company_id });
  return res.data;
});

export const logsSlice = createSlice({
  name: 'EDlogs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(logsList.fulfilled, (state, action) => {
      state.logs = action.payload;
    });
    //
    builder.addCase(logsRead.fulfilled, (state, action) => {});
  },
});

export default logsSlice.reducer;
