import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { requestPassword, resetPassword } from '../redux/AuthCRUD';

const initialValues = {
  email: '',
};
const initialResetPasswordValues = {
  token: '',
  password: '',
};

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Wrong email format').min(3).required('Email adresi zorunludur.'),
});

const resetPasswordSchema = Yup.object().shape({
  token: Yup.string().min(6, 'Doğrulama kodu 6 haneli olmalıdır.').max(6).required('Doğrulama kodu 6 haneli olmalıdır.'),
  password: Yup.string()
    .required('Lütfen şifrenizi girin.')
    .matches(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/, '8 Karakter, Bir Büyük Harf, Bir Küçük Harf, Bir Sayı ve Bir Özel Durum Karakteri İçermelidir'),
  // .matches(/^(?=.*[a-z])/, ' Bir küçük harf karakter içermelidirr')
  // .matches(/^(?=.*[A-Z])/, '  Bir büyük harf karakter içermelidir')
  // .matches(/^(?=.*[0-9])/, '  Bir sayı karakteri içermelidir')
});

export function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState('');

  const resetPassFormik = useFormik({
    initialValues: initialResetPasswordValues,
    validationSchema: resetPasswordSchema,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);
      resetPassword(values.token, values.password, email)
        .then(({ data: { result } }) => {
          setLoading(false);
          toast.success('Şifreniz başarıyla sıfırlandı.');
        })
        .catch((error: any) => {
          setLoading(false);
          setSubmitting(false);
          toast.error(error?.response?.data?.message);
        });
    },
  });

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);
      requestPassword(values.email)
        .then(({ data: { email } }) => {
          setLoading(false);
          setSuccess(true);
          setEmail(email);
          toast.success('Şifre sıfırlama bağlantısı email adresinize gönderildi.');
        })
        .catch((error: any) => {
          setLoading(false);
          setSubmitting(false);
          setSuccess(false);
          toast.error(error?.response?.data?.message);
        });
    },
  });
  const successControl = () => {
    if (!success)
      return (
        <div className="fv-row mb-10">
          <label className="form-label fw-bold text-gray-600 fs-6 ">Email</label>
          <input
            type="email"
            placeholder=""
            autoComplete="off"
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.email && formik.errors.email },
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              },
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span className="text-danger" role="alert">
                  {formik.errors.email}
                </span>
              </div>
            </div>
          )}
        </div>
      );
    else
      return (
        <div className="fv-row mb-10">
          <label className="form-label fw-bolder text-gray-900 fs-6">Doğrulama Kodu</label>
          <input
            type="text"
            placeholder=""
            autoComplete="off"
            {...resetPassFormik.getFieldProps('token')}
            className={clsx(
              'form-control form-control-lg form-control-solid mb-4',
              { 'is-invalid': resetPassFormik.touched.token && resetPassFormik.errors.token },
              {
                'is-valid': resetPassFormik.touched.token && !resetPassFormik.errors.token,
              },
            )}
          />
          {resetPassFormik.touched.token && resetPassFormik.errors.token && (
            <div className="fv-plugins-message-container mb-3">
              <div className="fv-help-block">
                <span className="text-danger" role="alert">
                  {resetPassFormik.errors.token}
                </span>
              </div>
            </div>
          )}
          <label className="form-label fw-bolder text-gray-900 fs-6">Şifre</label>
          <input
            type="password"
            placeholder=""
            autoComplete="off"
            {...resetPassFormik.getFieldProps('password')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': resetPassFormik.touched.password && resetPassFormik.errors.password },
              {
                'is-valid': resetPassFormik.touched.password && !resetPassFormik.errors.password,
              },
            )}
          />
          {resetPassFormik.touched.password && resetPassFormik.errors.password && (
            <div className="fv-plugins-message-container mb-3 mt-3">
              <div className="fv-help-block">
                <span className="text-danger" role="alert">
                  {resetPassFormik.errors.password}
                </span>
              </div>
            </div>
          )}
        </div>
      );
  };

  return (
    <>
      <form
        className="form w-100 px-3 fv-plugins-bootstrap5 fv-plugins-framework w-xl-550px"
        noValidate
        id="kt_login_password_reset_form"
        onSubmit={success ? resetPassFormik.handleSubmit : formik.handleSubmit}
      >
        <div className="text-center mb-10">
          {/* begin::Title */}
          <h1 className="fw-bolder fs-2x text-gray-800 mb-10">Şifremi Sıfırla</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className="text-gray-400 fw-bold fs-4">{!success ? 'Şifrenizi sıfırlamak için email adresinizi girin.' : 'Email adresinize gönderilen doğrulama kodunu girin.'}</div>
          {/* end::Link */}
        </div>

        {/* end::Title */}

        {/* begin::Form group */}
        {successControl()}
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className="d-flex flex-wrap justify-content-center pb-lg-0">
          <button type="submit" id="kt_password_reset_submit" className="btn btn-lg btn-light-primary fw-bolder me-4">
            <span className="indicator-label">{success ? 'Sıfırla' : 'Gönder'}</span>
            {loading && (
              <span className="indicator-progress">
                Lütfen bekleyin...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
          <Link to="/auth/login">
            <button type="button" id="kt_login_password_reset_form_cancel_button" className="btn btn-lg btn-secondary fw-bolder" disabled={formik.isSubmitting || !formik.isValid}>
              İptal
            </button>
          </Link>{' '}
        </div>
        {/* end::Form group */}
      </form>
    </>
  );
}
