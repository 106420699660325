import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { KTSVG } from 'src/_metronic/helpers';
import Restricted from 'src/app/permissions/restricted';
import BranchesCreate from '../modals/branches-create';
import BranchesDelete from '../modals/branches-delete';
import BranchesUpdate from '../modals/branches-update';
import { useBranchesList } from '../service';

type Props = {};

export default function List({}: Props) {
  const [list, setList] = useState<any>();
  const [branchesDeleteModal, setBranchesDeleteModal] = useState(false);
  const [branchesUpdateModal, setBranchesUpdateModal] = useState(false);
  const [branchesCreateModal, setBranchesCreateModal] = useState(false);

  const onErrorFetch = (e: any) => e?.response?.data?.message && toast.error(e?.response?.data?.message);
  const { refetch: branchesRefetch, data: branchesList, isLoading: branchesIsLoading, isFetching: branchesFetching } = useBranchesList(onErrorFetch);

  const notResult = useMemo(
    () =>
      !branchesIsLoading &&
      !branchesList?.length && (
        <tr className="text-center">
          <td colSpan={5}>
            <KTSVG path="/media/icons/duotone/Text/Filter.svg" className="svg-icon-2 svg-icon-muted mx-3" />
            Herhangi bir şubeniz bulunmuyor.
          </td>
        </tr>
      ),
    [branchesList, branchesIsLoading],
  );

  const handleUpdate = (branch: any) => {
    setList(branch);
    setBranchesUpdateModal(true);
  };

  const handleDelete = (branch: any) => {
    setList(branch);
    setBranchesDeleteModal(true);
  };
  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-5">
        <h3>Şubeler</h3>
        <Restricted to="ROLE_BRANCHES_CREATE">
          <div className="d-flex align-items-center">
            <button className="btn btn-primary btn-sm me-3 d-flex" onClick={() => setBranchesCreateModal(true)}>
              Şube Ekle
              {/* {isRegistrationExcelDownloadLoading && (
              <span className="indicator-progress" style={{ display: 'block' }}>
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )} */}
            </button>
          </div>
        </Restricted>
      </div>

      <div className="card">
        <div className="card-body w-100">
          <div className="table-responsive">
            {/* begin::Table */}
            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
              {/* begin::Table head */}
              <thead>
                <tr className="fw-bolder text-muted">
                  <th className="min-w-100px">Şube Adı</th>
                  <th className="min-w-100px">Şube Yetkili Adı</th>
                  <th className="min-w-100px">Şube Yetkili Soyadı</th>
                  <th className="min-w-100px">Şube İletişim</th>
                  <th className="min-w-100px">Oluşturma Tarihi</th>
                  <th></th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {branchesList &&
                  branchesList?.map((list: any, index: number) => (
                    <tr key={index}>
                      <td>{list?.company_name}</td>
                      <td>{list?.contact_name}</td>
                      <td>{list?.contact_surname}</td>
                      <td>{list?.contact_phone}</td>
                      <td>{moment(list?.created_at).format('DD.MM.YYYY')}</td>
                      <div className="card-footer d-flex justify-content-start px-0 py-5">
                        <Restricted to="ROLE_BRANCHES_EDIT">
                          <button className="btn btn-light-warning btn-sm" onClick={() => handleUpdate(list)}>
                            Güncelle
                          </button>
                        </Restricted>
                        <Restricted to="ROLE_BRANCHES_DELETE">
                          <button className="btn btn-light-danger btn-sm mx-4" onClick={() => handleDelete(list)}>
                            Sil
                          </button>
                        </Restricted>
                      </div>
                    </tr>
                  ))}
                {notResult}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <BranchesDelete show={branchesDeleteModal} handleClose={() => setBranchesDeleteModal(false)} detailList={list} refetchList={branchesRefetch} />
      <BranchesUpdate show={branchesUpdateModal} handleClose={() => setBranchesUpdateModal(false)} detailList={list} refetchList={branchesRefetch} setDetailList={setList} />
      <BranchesCreate show={branchesCreateModal} handleClose={() => setBranchesCreateModal(false)} refetchList={branchesRefetch} />
    </>
  );
}
