import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import store from '../../app/store';

const REACT_APP_API_URL = `${process.env.REACT_APP_API_URL}`;

export interface IUnRead {
  notifications_unread_count: number;
  logs_unread_count: number;
  messages_unread_count: number;
}

const initialState: IAuthState = {
  unreads: { notifications_unread_count: 0, logs_unread_count: 0, messages_unread_count: 0 },
};

export interface IAuthState {
  unreads?: IUnRead;
}
export const unreadsList = createAsyncThunk('unreads/list', async () => {
  const company_id = store.getState()?.auth?.user?.company?.id;
  const user_id = store.getState()?.auth?.user?.id;
  var params = { company_id, user_id };
  const res = await axios.get<any>(`${REACT_APP_API_URL}/unreads/list`, { params });
  return res.data;
});

export const unreadsSlice = createSlice({
  name: 'EDunreads',
  initialState,
  reducers: {
    _clearNotifications: (state) => {
      if (state.unreads) {
        state.unreads.notifications_unread_count = 0;
      }
    },
    _clearLogs: (state) => {
      if (state.unreads) {
        state.unreads.logs_unread_count = 0;
      }
    },
    _clearMessages: (state) => {
      if (state.unreads) {
        state.unreads.messages_unread_count = 0;
      }
    },
    _decrementCountMessages: (state, payload) => {
      if (state.unreads) {
        state.unreads.messages_unread_count = Number(state.unreads.messages_unread_count) - Number(payload?.payload);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(unreadsList.fulfilled, (state, action) => {
      state.unreads = action.payload;
    });
  },
});

export const { _clearNotifications, _clearLogs, _clearMessages, _decrementCountMessages } = unreadsSlice.actions;
export default unreadsSlice.reducer;
