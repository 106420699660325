/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react';
import CurrencyFormat from 'react-currency-format';
import clsx from 'clsx';
import { useFormikContext } from 'formik';

const Step1: FC = () => {
  const formik: any = useFormikContext();
  return (
    <div className="w-100">
      <div className="pb-10 pb-lg-15">
        <h2 className="fw-bolder d-flex align-items-center text-dark">
          Hesap Bilgileriniz
          <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Billing is issued based on your selected account type"></i>
        </h2>

        <div className="text-gray-500 fw-normal fs-6">
          Kişisel bilgilerinizin güvenliği ve gizliliği bizim önceliğimizdir; bilgileriniz yalnızca size daha iyi hizmet sunmak amacıyla kullanılır ve üçüncü şahıslarla paylaşılmaz
        </div>
      </div>

      <div className="fv-row">
        <div className="row">
          <div className="col-xl-6">
            <label className="form-label fw-bold text-gray-600 fs-6">Adınız</label>
            <input
              type="text"
              autoComplete="off"
              {...formik.getFieldProps('contact_name')}
              className={clsx(
                'form-control form-control-lg form-control-solid fs-6',
                {
                  'is-invalid border-danger': formik.touched.contact_name && formik.errors.contact_name,
                },
                {
                  'border-success': formik.touched.contact_name && !formik.errors.contact_name,
                },
              )}
            />
            {formik.touched.contact_name && formik.errors.contact_name && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span className="text-danger" role="alert">
                    {formik.errors.contact_name}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="col-xl-6">
            {/* begin::Form group contact_surname */}
            <div className="fv-row mb-5">
              <label className="form-label fw-bold text-gray-600 fs-6">Soyadınız</label>
              <input
                type="text"
                autoComplete="off"
                {...formik.getFieldProps('contact_surname')}
                className={clsx(
                  'form-control form-control-lg form-control-solid fs-6',
                  {
                    'is-invalid border-danger': formik.touched.contact_surname && formik.errors.contact_surname,
                  },
                  {
                    'border-success': formik.touched.contact_surname && !formik.errors.contact_surname,
                  },
                )}
              />
              {formik.touched.contact_surname && formik.errors.contact_surname && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span className="text-danger" role="alert">
                      {formik.errors.contact_surname}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="fv-row mb-5">
        <div className="row ">
          <div className="col-xl-12">
            <label className="form-label fw-bold text-gray-600 fs-6">Telefon Numarası</label>
            <CurrencyFormat
              format="(###) ###-####"
              mask="_"
              name={'contact_phone'}
              onBlur={formik.handleBlur}
              value={formik.values?.contact_phone?.formattedValue}
              onValueChange={(e) => formik.setFieldValue('contact_phone', e)}
              className={clsx(
                'form-control form-control-lg form-control-solid fs-6',
                {
                  'is-invalid border-danger': formik.touched.contact_phone && formik.errors.contact_phone,
                },
                {
                  'border-success': formik.touched.contact_phone && !formik.errors.contact_phone,
                },
              )}
            />
            {formik.touched.contact_phone && (formik.errors.contact_phone?.formattedValue || formik.errors.contact_phone?.value) && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span className="text-danger" role="alert">
                    {formik.errors.contact_phone?.formattedValue || formik.errors.contact_phone?.value}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="fv-row">
        <div className="row">
          <div className="col-xl-12">
            {/* begin::Form group contact_surname */}
            <div className="fv-row mb-5">
              <label className="form-label fw-bold text-gray-600 fs-6">Email</label>
              <input
                placeholder="Email"
                type="email"
                autoComplete="off"
                {...formik.getFieldProps('email')}
                className={clsx(
                  'form-control form-control-lg form-control-solid fs-6',
                  { 'is-invalid border-danger': formik.touched.email && formik.errors.email },
                  {
                    'border-success': formik.touched.email && !formik.errors.email,
                  },
                )}
              />
              {formik.touched.email && formik.errors.email && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span className="text-danger" role="alert">
                      {formik.errors.email}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Step1 };
