import React from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { checkIsActive, KTSVG, toAbsoluteUrl } from '../../../helpers';

type Props = {
  to: string;
  title?: string;
  icon?: string;
  fontIcon?: string;
  hasArrow?: boolean;
  hasBullet?: boolean;
  logo?: boolean;
};

const MenuItem: React.FC<Props> = ({ to, title, icon, fontIcon, hasArrow = false, hasBullet = false, logo }) => {
  const { pathname } = useLocation();

  return (
    <div className="menu-item me-lg-1">
      <Link
        className={clsx('fs-5 ', {
          active: checkIsActive(pathname, to) && !logo,
          'menu-link py-3': !logo,
        })}
        to={to}
      >
        {hasBullet && (
          <span className="menu-bullet">
            <span className="bullet bullet-dot"></span>
          </span>
        )}

        {icon && (
          <span className="menu-icon">
            {/* {logo ? <img alt="Logo" className="h-35px logo svg-icon-5x" src={toAbsoluteUrl('/media/logos/logo-4.svg')} /> : <KTSVG path={icon} className="svg-icon-2" />} */}
            {logo ? (
              <div className="d-flex flex-column align-items-center ">
                <span style={{ color: '#424874' }} className="fw-bolder fs-1">
                  EDUTHON
                </span>
              </div>
            ) : (
              <KTSVG path={icon} className="svg-icon-2" />
            )}
          </span>
        )}

        {fontIcon && (
          <span className="menu-icon">
            <i className={clsx('bi fs-3', fontIcon)}></i>
          </span>
        )}

        <span className="menu-title fs-6">{title}</span>

        {hasArrow && <span className="menu-arrow"></span>}
      </Link>
    </div>
  );
};

export { MenuItem };
