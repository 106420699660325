import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap-v5';
import CurrencyFormat from 'react-currency-format';
import { toast } from 'react-toastify';
import { Formik, FormikProps, FormikValues } from 'formik';
import { KTSVG } from 'src/_metronic/helpers';
import DragFile from 'src/app/utils/DragFile';
import { _setStamp } from 'src/services/redux/slices/auth/auth-slice';
import * as Yup from 'yup';
import { useBranchesCreate } from '../service';

type Props = {
  show: boolean;
  handleClose: Function;
  refetchList: any;
};

export default function BranchesCreate({ show, handleClose, refetchList }: Props) {
  const [file, setFile] = useState(null);
  const formikRef = useRef<FormikProps<any>>(null);
  const onSuccessFetch = (e: any) => {
    toast.success(`Şube başarıyla eklendi.`);
    refetchList();
    dissmisLocation();
  };
  const onErrorFetch = (e: any) => e?.response?.data?.message && toast.error(e?.response?.data?.message);
  const { isLoading, mutate, reset } = useBranchesCreate(onSuccessFetch, onErrorFetch);

  const branchesSchema = Yup.object().shape({
    company_name: Yup.string().trim().min(1, 'Lütfen şube adını belirtin').required('Lütfen şube adını belirtin'),
    contact_name: Yup.string().trim().min(1, 'Lütfen şube yetkili adını belirtin').required('Lütfen şube yetkili adını belirtin'),
    contact_surname: Yup.string().trim().min(1, 'Lütfen şube yetkili soyadını belirtin').required('Lütfen şube yetkili soyadını belirtin'),
    contact_phone: Yup.object().shape({
      floatValue: Yup.string().trim().min(1, 'Lütfen şube yetkili telefon numarasını belirtin.').required('Lütfen şube yetkili telefon numarasını belirtin.'),
      formattedValue: Yup.string().trim().min(1, 'Lütfen şube yetkili telefon numarasını belirtin.').required('Lütfen şube yetkili telefon numarasını belirtin.'),
      value: Yup.string().trim().min(1, 'Lütfen şube yetkili telefon numarasını belirtin.').required('Lütfen şube yetkili telefon numarasını belirtin.'),
    }),
  });
  var initialValues = {
    company_name: '',
    contact_name: '',
    contact_surname: '',
    contact_phone: {
      formattedValue: '',
      value: '',
      floatValue: 0,
    },
  };
  const dissmisLocation = () => {
    formikRef?.current?.resetForm();
    handleClose();
  };

  return (
    <Modal show={show} onHide={dissmisLocation} centered>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={branchesSchema}
        onSubmit={(values, { setSubmitting }) => {
          const body = { ...values };
          body.contact_phone = body?.contact_phone?.value;
          mutate(body);
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, isValid }: /* and other goodies */ any) => (
          <form onSubmit={handleSubmit}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Şube Ekle
                </h5>
                <button type="button" className="btn-close" onClick={dissmisLocation} aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="row mb-5">
                  <div className="col-md-12">
                    <label className="form-label required">Şube Adı</label>
                    <input
                      type="text"
                      name="company_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.company_name}
                      className={`form-control form-control-solid ${errors.company_name && touched.company_name && 'is-invalid border-danger'}`}
                    />
                    {errors.company_name && touched.company_name && <span className="text-danger">{errors.company_name}</span>}
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-md-12">
                    <label className="form-label required">Yetkili Adı</label>
                    <input
                      name="contact_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.contact_name}
                      className={`form-control form-control-solid ${errors.contact_name && touched.contact_name && 'is-invalid border-danger'}`}
                    />
                    {errors.contact_name && touched.contact_name && <span className="text-danger">{errors.contact_name}</span>}
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-md-12">
                    <label className="form-label required">Yetkili Soyadı</label>
                    <input
                      name="contact_surname"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.contact_surname}
                      className={`form-control form-control-solid ${errors.contact_surname && touched.contact_surname && 'is-invalid border-danger'}`}
                    />
                    {errors.contact_surname && touched.contact_surname && <span className="text-danger">{errors.contact_surname}</span>}
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-md-12">
                    <label className="form-label required">Yetkili Telefon Numarası</label>
                    <CurrencyFormat
                      format="(###) ###-####"
                      mask="_"
                      name={'contact_phone'}
                      value={values?.contact_phone?.formattedValue}
                      onValueChange={(e) => setFieldValue('contact_phone', e)}
                      className={`form-control form-control-solid ${errors.contact_phone && touched.contact_phone && 'is-invalid border-danger'}`}
                    />
                    {errors.contact_phone && (
                      <div className="fv-plugins-message-container text-danger">
                        <div className="fv-help-block">{errors.contact_phone?.value}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="alert alert-secondary d-flex align-items-center p-5">
                      <span className="svg-icon svg-icon-2hx svg-icon-primary me-3">
                        <KTSVG path="/media/icons/duotone/Home/Circle-info-solid.svg" className="svg-icon-primary text-primary me-5  " />
                      </span>
                      <div className="d-flex flex-column">
                        <span className="fs-7">
                          Yeni bir şube eklemek istediğinizde abonelik tarihine ek olarak <b>150 TL</b> yansıyacaktır.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={dissmisLocation}>
                  Vazgeç
                </button>
                <button type="submit" className="btn btn-light-success" disabled={isSubmitting}>
                  Ekle
                  {isLoading && <span className="spinner-border spinner-border-sm align-middle mx-2 text-white"></span>}
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
}
