import React, { useState } from 'react';
import { Main } from 'src/_metronic/partials';

type Props = {};

export default function LatePayment({}: Props) {
  const [paymentModal, setPaymentModal] = useState<boolean>(false);
  return (
    <>
      <div className="d-flex flex-column flex-root">
        <div className="d-flex flex-column flex-center flex-column-fluid">
          <div className="d-flex flex-column flex-center text-center p-10">
            <div className="card card-flush  w-lg-650px py-5">
              <div className="card-body py-15 py-lg-15">
                <h1 className="fw-bolder fs-1hx text-gray-900 mb-10">Geciken Ödeme!</h1>
                <div className="fw-normal fs-6 text-gray-700 mb-7 text-start">
                  <div className="mb-3">Değerli Müşterimiz, </div>
                  <div className="mb-3">
                    Ödeme tarihinin geçmiş olduğunu fark ettik ve henüz ödemenizi alamadık. Bu konuda bize yardımcı olabilir ve ödemenizi en kısa sürede gerçekleştirebilirseniz,
                    memnuniyet duyarız.
                  </div>
                  <div className="mb-3">Anlayışınız için teşekkür ederiz.</div>
                  Saygılarımızla,
                </div>
                <div className="mb-10">
                  <img src="/media/illustrations/banking.png" className="mw-100 mh-300px " alt="" />
                </div>
                <div className="mb-0">
                  <button className="btn btn-sm btn-light-primary" onClick={() => setPaymentModal(true)}>
                    Ödeme Yap
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Main show={paymentModal} handleClose={() => setPaymentModal(false)} />
    </>
  );
}
