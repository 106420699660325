import React, { useEffect } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { useAppDispatch } from 'src/services/redux/app/store';
import { _logOut } from 'src/services/redux/slices/auth/auth-slice';

export function Logout() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(_logOut());
    document.location.reload();
  }, [dispatch]);

  return null;
  // return (
  //   <Switch>
  //     <Redirect to='/auth/login' />
  //   </Switch>
  // )
}
