/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react';
import clsx from 'clsx';
import { Field, ErrorMessage } from 'formik';
import { useFormikContext } from 'formik';
import { KTSVG } from 'src/_metronic/helpers';

const Step2: FC = () => {
  const formik: any = useFormikContext();

  return (
    <div className="w-100">
      <div className="pb-10 pb-lg-15">
        <h2 className="fw-bolder text-dark">Kurum Bilgileri</h2>

        <div className="text-gray-500 fw-normal fs-6">
          Eğitim kurumları sistemi, öğrenci ve eğitim sürecini yönetmek için kurumların tanımlayıcı bilgilerini alarak verimliliği artırır.
        </div>
      </div>
      <div className="fv-row">
        <div className="row">
          <div className="col-xl-12">
            <div className="fv-row mb-5">
              <label className="form-label fw-bold text-gray-600 fs-6">Kurum Adı</label>
              <input
                type="text"
                autoComplete="off"
                {...formik.getFieldProps('company_name')}
                className={clsx(
                  'form-control form-control-lg form-control-solid fs-6',
                  {
                    'is-invalid border-danger': formik.touched.company_name && formik.errors.company_name,
                  },
                  {
                    'border-success': formik.touched.company_name && !formik.errors.company_name,
                  },
                )}
              />
              {formik.touched.company_name && formik.errors.company_name && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span className="text-danger" role="alert">
                      {formik.errors.company_name}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="fv-row">
        <div className="row">
          <div className="col-xl-12">
            {/* begin::Form group contact_surname */}
            <div className="fv-row mb-5">
              <label className="form-label fw-bold text-gray-600 fs-6">Kurum Web Sitesi</label>
              <input
                autoComplete="off"
                {...formik.getFieldProps('web_site')}
                className={clsx(
                  'form-control form-control-lg form-control-solid fs-6',
                  { 'is-invalid border-danger': formik.touched.web_site && formik.errors.web_site },
                  {
                    'border-success': formik.touched.web_site && !formik.errors.web_site,
                  },
                )}
              />
              {formik.touched.web_site && formik.errors.web_site && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span className="text-danger" role="alert">
                      {formik.errors.web_site}
                    </span>
                  </div>
                </div>
              )}
              <div className="form-text">
                Kurum web sitenizi ücretsiz olarak sunucularımızda barındırarak kurulum sürecini sizin için kolaylaştırıyoruz. Dijital varlığınızı güçlendirin ve daha geniş bir
                kitleye erişim sağlayın!
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fv-row">
        <div className="row">
          <div className="col-xl-12">
            {/* begin::Form group contact_surname */}
            <div className="fv-row mb-5">
              <label className="form-label fw-bold text-gray-600 fs-6">Vergi No.</label>
              <input
                autoComplete="off"
                type="number"
                {...formik.getFieldProps('tax_number')}
                className={clsx(
                  'form-control form-control-lg form-control-solid fs-6',
                  { 'is-invalid border-danger': formik.touched.tax_number && formik.errors.tax_number },
                  {
                    'border-success': formik.touched.tax_number && !formik.errors.tax_number,
                  },
                )}
              />
              {formik.touched.tax_number && formik.errors.tax_number && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span className="text-danger" role="alert">
                      {formik.errors.tax_number}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Step2 };
