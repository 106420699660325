import { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import NotAllowed from 'src/app/permissions/not-allowed';
import Restricted from 'src/app/permissions/restricted';
import List from './components/list';

const BranchesManagementPage: FC = () => {
  return (
    <>
      <Switch>
        <Route path="/branches/list" exact={true}>
          <Restricted to="ROLE_BRANCHES" fallback={<NotAllowed />}>
            <List />
          </Restricted>
        </Route>
        <Redirect to="/branches/list" />
      </Switch>
    </>
  );
};

export default BranchesManagementPage;
