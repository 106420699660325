import { useMutation } from '@tanstack/react-query';
import React, { Suspense, lazy, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useAppSelector } from 'src/services/redux/app/store';
import { FallbackView } from '../../_metronic/partials';
import { MenuTestPage } from '../pages/MenuTestPage';
import BranchesManagementPage from '../pages/branches';
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper';
import ReleaseNotes from '../pages/release-notes';
import { getOrRegisterServiceWorker, onMessageListener, requestForToken } from '../utils/firebase';

const REACT_APP_API_URL = `${process.env.REACT_APP_API_URL}`;

export function PrivateRoutes() {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'));
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'));
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'));
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'));
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'));
  const ChatPage = lazy(() => import('../pages/messages'));
  const ApplicationPage = lazy(() => import('../pages/applications'));
  const AnnouncementPage = lazy(() => import('../pages/announcement'));
  const AboutUsPage = lazy(() => import('../pages/about-us'));
  const ContactPage = lazy(() => import('../pages/contact'));
  const FoundersPage = lazy(() => import('../pages/founders'));
  const JobApplicationsPage = lazy(() => import('../pages/job-applications'));
  const PagesPage = lazy(() => import('../pages/page-management'));
  const PopupPage = lazy(() => import('../pages/popup'));
  const SeoPage = lazy(() => import('../pages/seo'));
  const SlidePage = lazy(() => import('../pages/slide'));
  const SubscribersPage = lazy(() => import('../pages/subscribers'));
  const FinancePage = lazy(() => import('../pages/finance'));
  const BanksPage = lazy(() => import('../pages/banks'));
  const CashesPage = lazy(() => import('../pages/cashes'));
  const UsersPage = lazy(() => import('../pages/users'));
  const LessonsPage = lazy(() => import('../pages/lessons'));
  const PaymentsPage = lazy(() => import('../pages/payments'));
  const TransfersPage = lazy(() => import('../pages/transfer'));
  const ReportsPage = lazy(() => import('../pages/reports'));
  const TodosPage = lazy(() => import('../pages/todos'));
  const PeriodsPage = lazy(() => import('../pages/periods'));
  const EmailPage = lazy(() => import('../pages/email'));
  const MenusPage = lazy(() => import('../pages/menus'));
  const BulkTransactionsPage = lazy(() => import('../pages/bulk-transactions'));
  const ProductsPage = lazy(() => import('../pages/products'));
  const EmployeesPage = lazy(() => import('../pages/employees'));
  const CurrentAccountsPage = lazy(() => import('../pages/current-accounts'));
  const HomeworkPage = lazy(() => import('../pages/homeworks'));
  const CommissionPage = lazy(() => import('../pages/commission'));
  const TicketsPage = lazy(() => import('../pages/tickets'));
  const IncomeExpensesPage = lazy(() => import('../pages/income-expenses'));
  const ReleaseNotesPage = lazy(() => import('../pages/release-notes'));
  const DeviceOperationsManagementPage = lazy(() => import('../pages/device-operations'));
  const OpticalPage = lazy(() => import('../pages/optical'));

  const { user } = useAppSelector((state) => state.auth);

  onMessageListener()
    .then((payload: any) => {
      toast.info(
        <div className="w-100 d-flex flex-column  justify-content-center h-100 mt-3 ">
          {/* insert your icon here */}
          <span style={{ fontWeight: 'bold', color: '#000' }}>{payload?.notification?.title}</span>
          <p>{payload?.notification?.body}</p>
        </div>,
        {
          autoClose: false,
        },
      );
    })
    .catch((err) => {
      // ('failed: ', err)
    });

  getOrRegisterServiceWorker();

  const notificationTokenUpdateMutation = useMutation<any, any, any>({
    mutationKey: ['notification-token-update'],
    mutationFn: (params) => {
      return axios.post(REACT_APP_API_URL + '/auth/change-notification-token', { id: user?.id, notification_token: params, company_id: user?.company_id });
    },
    onError: (err) => {
      // toast.error(err.response?.data?.message || 'İşleminizi bilinmeyen bir hatadan ötürü tamamlayamadık.', {
      //   autoClose: 2000,
      // });
    },
    onSuccess: () => {},
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await requestForToken();
        notificationTokenUpdateMutation.mutate(token);
      } catch (error) {
        console.error('Error fetching token: ', error);
      }
    };

    fetchData();
  }, []);

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path="/dashboard" component={DashboardWrapper} />
        <Route path="/applications" component={ApplicationPage} />
        <Route path="/site-operations/announcement" component={AnnouncementPage} />
        <Route path="/site-operations/contact" component={ContactPage} />
        <Route path="/site-operations/aboutus" component={AboutUsPage} />
        <Route path="/site-operations/founders" component={FoundersPage} />
        <Route path="/site-operations/job" component={JobApplicationsPage} />
        <Route path="/site-operations/pages" component={PagesPage} />
        <Route path="/site-operations/popup" component={PopupPage} />
        <Route path="/site-operations/seo" component={SeoPage} />
        <Route path="/site-operations/slide" component={SlidePage} />
        <Route path="/site-operations/subscribers" component={SubscribersPage} />
        <Route path="/site-operations/menus" component={MenusPage} />
        <Route path="/finance/operations" component={FinancePage} />
        <Route path="/finance/income" component={IncomeExpensesPage} />
        <Route path="/finance/banks" component={BanksPage} />
        <Route path="/finance/cashes" component={CashesPage} />
        <Route path="/finance/transfer" component={TransfersPage} />
        <Route path="/finance/current-accounts" component={CurrentAccountsPage} />
        <Route path="/bulk-transactions" component={BulkTransactionsPage} />
        <Route path="/reports" component={ReportsPage} />
        <Route path="/users" component={UsersPage} />
        <Route path="/todos" component={TodosPage} />
        <Route path="/periods" component={PeriodsPage} />
        <Route path="/profile/payments" component={PaymentsPage} />
        <Route path="/account" component={AccountPage} />
        <Route path="/homework" component={HomeworkPage} />
        <Route path="/employees" component={EmployeesPage} />
        <Route path="/lessons" component={LessonsPage} />
        <Route path="/commission" component={CommissionPage} />
        <Route path="/tickets" component={TicketsPage} />
        <Route path="/email" component={EmailPage} />
        <Route path="/branches" component={BranchesManagementPage} />
        <Route path="/products" component={ProductsPage} />
        <Route path="/device-operations" component={DeviceOperationsManagementPage} />
        <Route path="/optical" component={OpticalPage} />

        {/* <Route path='/crafted/pages/profile' component={ProfilePage} />  */}
        {/* <Route path="/builder" component={BuilderPageWrapper} /> */}
        {/* <Route path="/crafted/pages/wizards" component={WizardsPage} /> */}
        <Route path="/crafted/widgets" component={WidgetsPage} />
        <Route path="/messages" component={ChatPage} />
        {/* <Route path="/menu-test" component={MenuTestPage} /> */}
        <Route path="/release-notes" component={ReleaseNotesPage} />
        <Redirect from="/auth" to="/dashboard" />
        <Redirect exact from="/" to="/dashboard" />
        <Redirect to="error/404" />
      </Switch>
    </Suspense>
  );
}
