/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react';
import clsx from 'clsx';
import { Field, ErrorMessage } from 'formik';

const Step3: FC = () => {
  return (
    <div className="w-100">
      <div className="pb-10 pb-lg-12">
        <h2 className="fw-bolder text-dark">Web Site Bilgisi</h2>

        <div className="text-gray-500 fw-normal fs-6">
          Web sitesini belirtilen DNS adreslerine yönlendirmek, internet trafiğinin doğru sunucuya ulaşmasını sağlar. Bu yönlendirme, kullanıcıların web sitenize erişimini
          sağlayarak sitenizin online ortamda görünür olmasını sağlar.
        </div>
      </div>
      <div className="fv-row">
        <div className="row">
          <div className="col-xl-12">
            <div className="fv-row mb-5">
              <input autoComplete="off" disabled value={'ns1.apithon.com.tr'} className={clsx('form-control form-control-lg form-control-solid fs-6')} />
            </div>
          </div>
        </div>
      </div>

      <div className="fv-row">
        <div className="row">
          <div className="col-xl-12">
            {/* begin::Form group contact_surname */}
            <div className="fv-row mb-5">
              <input autoComplete="off" disabled value={'ns2.apithon.com.tr'} className={clsx('form-control form-control-lg form-control-solid fs-6')} />
            </div>
          </div>
        </div>
      </div>
      <div className="fv-row">
        <div className="alert alert-dismissible bg-light-primary border border-primary d-flex flex-column flex-sm-row p-5 mb-10">
          <div className="d-flex flex-column text-primary pe-0 pe-sm-10">
            <span>
              Web sitenizi yönlendirmek için, alan adı yönetim panelinizde DNS ayarlarında NS1.APITHON.COM.TR ve NS2.APITHON.COM.TR adreslerini nameserver olarak tanımlamanız
              yeterlidir.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Step3 };
