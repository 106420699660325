import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useAppSelector } from 'src/services/redux/app/store';

const REACT_APP_API_URL = `${process.env.REACT_APP_API_URL}`;

export const useDashboardList = (params: any, onError?: any) => {
  const { user } = useAppSelector((state) => state.auth);
  const requestParams = { company_id: user?.company_id, ...params, user_id: user?.id, limitHomeworkPast: 5 };
  const requestUrl = REACT_APP_API_URL + '/dashboard/list';

  const fetchData = async () => {
    const res = await axios.get<any>(`${requestUrl}`, { params: requestParams });
    return res?.data || {};
  };
  return useQuery(['dashboard-list'], fetchData, {
    refetchOnWindowFocus: false,
    onError,
    staleTime: Infinity,
    retry: false,
  });
};

export const useTodosUpdate = (onSuccess?: any, onError?: any) => {
  const { user } = useAppSelector((state) => state.auth);
  const requestUrl = REACT_APP_API_URL + '/todos/update';
  return useMutation(
    (formPayload: any) => {
      const requestParams = { company_id: user?.company_id, ...formPayload, user_id: user?.id };
      return axios.post<any>(`${requestUrl}`, requestParams);
    },
    { onError, onSuccess },
  );
};

export const useInviteCreate = (onSuccess?: any, onError?: any) => {
  const { user } = useAppSelector((state) => state.auth);
  const requestUrl = REACT_APP_API_URL + '/company/invite/create';
  return useMutation(
    (formPayload: any) => {
      const requestParams = { ...formPayload, user_id: user?.id, company_id: user?.company_id };
      return axios.post<any>(`${requestUrl}`, requestParams);
    },
    { onError, onSuccess },
  );
};
