/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react';
import ApexCharts, { ApexOptions } from 'apexcharts';
import moment from 'moment';
import { getCSSVariableValue } from '../../../assets/ts/_utils';
import { KTSVG } from '../../../helpers';
import { Dropdown1 } from '../../content/dropdown/Dropdown1';

type Props = {
  className: string;
  chartHeight: string;
  chartColor: string;
  dashboardList?: any;
};

const MixedWidget6: React.FC<Props> = ({ className, chartHeight, chartColor, dashboardList }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!chartRef.current && !dashboardList) {
      return;
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(chartHeight, chartColor, dashboardList));
    if (chart && dashboardList) {
      chart.render();
    }

    return () => {
      if (chart && dashboardList) {
        chart.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, dashboardList]);

  return (
    <div className={`card ${className}`}>
      {/* begin::Beader */}
      <div className="card-header border-0 py-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder fs-3 mb-1">Cari Hesap</span>

          <span className="text-muted fw-bold fs-7">Cari hesap istatistikleri</span>
        </h3>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body p-0 d-flex flex-column">
        {/* begin::Stats */}
        <div className="card-p pt-5 bg-white flex-grow-1">
          {/* begin::Row */}
          <div className="row g-0">
            {/* begin::Col */}
            <div className="col mr-8">
              {/* begin::Label */}
              <div className="fs-7 text-muted fw-bold">Toplam Cari Hesap Sayısı</div>
              {/* end::Label */}

              {/* begin::Stat */}
              <div className="d-flex align-items-center">
                <div className="fs-4 fw-bolder">
                  {dashboardList?.is_company_false_count ? Number(dashboardList?.is_company_false_count) + Number(dashboardList?.is_company_true_count) : 0}
                </div>
              </div>
              {/* end::Stat */}
            </div>
            {/* end::Col */}

            {/* begin::Col */}
            <div className="col">
              {/* begin::Label */}
              <div className="fs-7 text-muted fw-bold">Kurumsal Cari Hesap Sayısı</div>
              {/* end::Label */}

              {/* begin::Stat */}
              <div className="fs-4 fw-bolder">{Number(dashboardList?.is_company_true_count ?? 0)}</div>
              {/* end::Stat */}
            </div>
            {/* end::Col */}
          </div>
          {/* end::Row */}

          {/* begin::Row */}
          <div className="row g-0 mt-8">
            {/* begin::Col */}
            <div className="col mr-8">
              {/* begin::Label */}
              <div className="fs-7 text-muted fw-bold">Bireysel Cari Hesap Sayısı</div>
              {/* end::Label */}

              {/* begin::Stat */}
              <div className="fs-4 fw-bolder">{Number(dashboardList?.is_company_false_count ?? 0)}</div>
              {/* end::Stat */}
            </div>
            {/* end::Col */}

            {/* begin::Col */}
            <div className="col">
              {/* begin::Label */}
              <div className="fs-7 text-muted fw-bold">1 Aylık Toplam Tutar</div>
              {/* end::Label */}

              {/* begin::Stat */}
              <div className="d-flex align-items-center">
                <div className="fs-4 fw-bolder">{Number(dashboardList?.total_price_last_month ?? 0)?.toLocaleString('tr-TR')} TL</div>
              </div>
              {/* end::Stat */}
            </div>
            {/* end::Col */}
          </div>
          {/* end::Row */}
        </div>
        {/* end::Stats */}

        {/* begin::Chart */}
        <div ref={chartRef} className="mixed-widget-3-chart card-rounded-bottom"></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  );
};

const chartOptions = (chartHeight: string, chartColor: string, dashboardList: any): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-800');
  const strokeColor = getCSSVariableValue('--bs-gray-300');
  const baseColor = getCSSVariableValue('--bs-' + chartColor);
  const lightColor = getCSSVariableValue('--bs-light-' + chartColor);

  const seriesData = dashboardList?.daily_total_prices?.map((e: any) => Number(e?.total_price)?.toLocaleString('tr-TR'));
  const dayData = dashboardList?.daily_total_prices?.map((e: any) => moment(e?.day)?.format('DD.MM.YYYY'));

  return {
    series: [
      {
        name: 'Cari Hesap İşlemi',
        data: seriesData,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: chartHeight,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: dayData,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        show: false,
        position: 'front',
        stroke: {
          color: strokeColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    // yaxis: {
    //   min: 0,
    //   max: 150,
    //   labels: {
    //     show: false,
    //     style: {
    //       colors: labelColor,
    //       fontSize: '12px',
    //     },
    //   },
    // },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val + ' TL';
        },
      },
    },
    colors: [lightColor],
    markers: {
      colors: [lightColor],
      strokeColors: [baseColor],
      strokeWidth: 3,
    },
  };
};

export { MixedWidget6 };
