/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react';
import clsx from 'clsx';
import { Field, ErrorMessage, useFormikContext } from 'formik';
import { KTSVG, toAbsoluteUrl } from 'src/_metronic/helpers';

const Step4: FC = () => {
  const formik: any = useFormikContext();
  return (
    <div className="w-100">
      <div className="pb-10 pb-lg-15">
        <h2 className="fw-bolder text-dark">Şifre Tanımla</h2>

        <div className="text-gray-500 fw-normal fs-6">
          Güvenli bir giriş için, Eduthon sistemine erişim için en az 8 karakterden oluşan, rakam, sembol ve büyük/küçük harf içeren bir şifre oluşturmanız gerekmektedir.
        </div>
      </div>

      <div className="fv-row">
        <div className="row">
          <div className="col-xl-12">
            <div className="fv-row mb-5">
              <label className="form-label fw-bold text-gray-600 fs-6">Şifre</label>
              <input
                type="password"
                autoComplete="off"
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control form-control-lg form-control-solid fs-6',
                  {
                    'is-invalid border-danger': formik.touched.password && formik.errors.password,
                  },
                  {
                    'border-success': formik.touched.password && !formik.errors.password,
                  },
                )}
              />
              {formik.touched.password && formik.errors.password && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span className="text-danger" role="alert">
                      {formik.errors.password}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="fv-row">
        <div className="row">
          <div className="col-xl-12">
            <div className="fv-row mb-5">
              <label className="form-label fw-bold text-gray-600 fs-6">Şifre Tekrar</label>
              <input
                type="password"
                autoComplete="off"
                {...formik.getFieldProps('changepassword')}
                className={clsx(
                  'form-control form-control-lg form-control-solid fs-6',
                  {
                    'is-invalid border-danger': formik.touched.changepassword && formik.errors.changepassword,
                  },
                  {
                    'border-success': formik.touched.changepassword && !formik.errors.changepassword,
                  },
                )}
              />
              {formik.touched.changepassword && formik.errors.changepassword && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span className="text-danger" role="alert">
                      {formik.errors.changepassword}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="fv-row">
        <div className="row">
          <div className="col-xl-12">
            <div className="fv-row mb-5">
              <div className="d-flex flex-stack">
                <div className="me-5 fw-bold">
                  <label className="fs-6">Davet kodunuz var mı ?</label>
                  <div className="fs-7 text-muted">Eğer davet kodunuz var ise davet kodunuzu belirtin. Davet kodunuzu hatırlamıyorsanız lütfen Eduthon ile iletişime geçin.</div>
                </div>

                <label className="form-check form-switch form-check-custom form-check-solid">
                  <input className="form-check-input" type="checkbox" {...formik.getFieldProps('is_invite')} checked={formik.values.is_invite} id="is_invite" />
                </label>
              </div>
              {formik.values.is_invite && (
                <>
                  <label className="form-label fw-bold text-gray-600 fs-6 mt-5">Davet Kodunuz</label>
                  <input
                    type="text"
                    autoComplete="off"
                    {...formik.getFieldProps('invitation_code')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid fs-6',
                      {
                        'is-invalid border-danger': formik.touched.invitation_code && formik.errors.invitation_code,
                      },
                      {
                        'border-success': formik.touched.invitation_code && !formik.errors.invitation_code,
                      },
                    )}
                  />
                  {formik.touched.invitation_code && formik.errors.invitation_code && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span className="text-danger" role="alert">
                          {formik.errors.invitation_code}
                        </span>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Step4 };
