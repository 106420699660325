/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { signInWithGooglePopup } from 'src/app/utils/firebase';
import { _login } from 'src/services/redux/slices/auth/auth-slice';
import { logsList } from 'src/services/redux/slices/logs/logs-slice';
import { notificationsList } from 'src/services/redux/slices/notifications/notifications-slice';
import { payment_detailsList } from 'src/services/redux/slices/payments-detail/payments-detail-slice';
import { unreadsList } from 'src/services/redux/slices/unread/unreads-slice';
import * as Yup from 'yup';
import { googleLogin, login } from '../redux/AuthCRUD';

const loginSchema = Yup.object().shape({
  email: Yup.string().email('Geçersiz email adresi girdiniz').min(3, 'Minimum 3 karakter').max(50, 'Maksimum 50 karakter').required('Email adresi zorunludur'),
  password: Yup.string().min(3, 'Minimum 3 karakter').max(50, 'Maksimum 50 karakter').required('Şifre zorunludur'),
});

const initialValues = {
  email: '',
  password: '',
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      login(values.email, values.password)
        .then(async ({ data }) => {
          setLoading(false);
          if (data?.user?.is_two_factor) {
            history.push({
              pathname: `/auth/two-factor`,
              state: { id: data?.user?.id, email: data?.user?.email },
            });
          } else {
            dispatch(_login(data));
            setTimeout(() => {
              dispatch(payment_detailsList());
              dispatch(notificationsList({ page: 1, limit: 10, sortBy: 'desc' }));
              dispatch(logsList({ page: 1, limit: 10 }));
              dispatch(unreadsList());
            }, 100);
          }
          // history.push()
        })
        .catch((error: any) => {
          toast.error(error?.response?.data?.message);
          setLoading(false);
          setSubmitting(false);
        });
    },
  });

  const handleGoogleLogin = async () => {
    setGoogleLoading(true);
    const response = await signInWithGooglePopup();
    googleLogin({ email: response.user.email })
      .then(async ({ data }) => {
        if (data?.user?.is_two_factor) {
          history.push({
            pathname: `/auth/two-factor`,
            state: { id: data?.user?.id, email: data?.user?.email },
          });
        } else {
          dispatch(_login(data));
          setTimeout(() => {
            dispatch(payment_detailsList());
            dispatch(notificationsList({ page: 1, limit: 10, sortBy: 'desc' }));
            dispatch(logsList({ page: 1, limit: 10 }));
            dispatch(unreadsList());
          }, 100);
        }
      })
      .catch((error: any) => {
        toast.error(error?.response?.data?.message);
      })
      .finally(() => {
        setGoogleLoading(false);
      });
  };

  return (
    <form className="form w-100 px-3 w-xl-550px" onSubmit={formik.handleSubmit} noValidate id="kt_login_signin_form">
      {/* begin::Heading */}
      <div className="text-center mb-10">
        <h1 className="fw-normaler fs-3x text-gray-800 mb-10">EDUTHON</h1>
        <div className="text-gray-600 fs-6 fw-normal">Eğitim sistemine dahil olun ücretsiz 1 ay kurumunuzu kolaylıkla yönetin. </div>
      </div>
      {process.env.REACT_APP_NODE_ENV === 'development' && (
        <div className="alert alert-dismissible bg-light border-1 border-dashed d-flex flex-row align-items-center flex-sm-row p-5 mb-10">
          <span className="svg-icon svg-icon-2hx svg-icon-success me-4 mb-5 mb-sm-0">
            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512">
              <path
                d="M256 0c4.6 0 9.2 1 13.4 2.9L457.7 82.8c22 9.3 38.4 31 38.3 57.2c-.5 99.2-41.3 280.7-213.6 363.2c-16.7 8-36.1 8-52.8 0C57.3 420.7 16.5 239.2 16 140c-.1-26.2 16.3-47.9 38.3-57.2L242.7 2.9C246.8 1 251.4 0 256 0z"
                fill="currentColor"
              />
            </svg>
          </span>
          <div className="d-flex flex-column text-warning pe-0 pe-sm-10">
            <h5 className="mb-1 fw-semibold text-gray-800">Demo Giriş Bilgileri</h5>
            <div className="fs-7">
              <span className="text-gray-700">Email:</span> <b className="text-gray-800">demo@apithon.com.tr</b>
            </div>
            <div className="fs-7">
              <span className="text-gray-700">Şifre:</span> <b className="text-gray-800">Demo.1234</b>
            </div>
          </div>
        </div>
      )}
      {/* begin::Form group */}
      <div className="fv-row mb-10">
        <label className="form-label fw-normal text-gray-600 fs-6">Email</label>
        <input
          placeholder="Email"
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            },
          )}
          type="email"
          name="email"
          autoComplete="off"
        />
      </div>
      {/* end::Form group */}
      {/* begin::Form group */}
      <div className="fv-row mb-10">
        <div className="d-flex justify-content-between mt-n5">
          <div className="d-flex flex-stack mb-2">
            {/* begin::Label */}
            <label className="form-label fw-normal text-gray-600 fs-6 mb-0">Şifre</label>

            {/* end::Label */}
            {/* begin::Link */}

            {/* end::Link */}
          </div>
          <Link to="/auth/forgot-password" className="link-primary fs-7 fw-normaler justify-content-end d-flex">
            Şifremi mi unuttun ?
          </Link>
        </div>
        <input
          type="password"
          autoComplete="off"
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            },
          )}
        />
      </div>
      {/* end::Form group */}
      {/* begin::Action */}
      <div className="">
        <button type="submit" id="kt_sign_in_submit" className="btn btn-lg btn-light-primary px-15 w-100  mb-5" disabled={formik.isSubmitting || !formik.isValid}>
          {!loading && <span className="indicator-label">İleri</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: 'block' }}>
              Lütfen bekleyin...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>

        {/* end::Google link */}
      </div>
      <div className="d-flex align-items-center mb-10 mt-10">
        <div className="border-bottom border-gray-300 mw-50 w-100"></div>
        <span className="fw-normal text-gray-400 fs-7 mx-2">VEYA</span>
        <div className="border-bottom border-gray-300 mw-50 w-100"></div>
      </div>
      <button
        onClick={handleGoogleLogin}
        type="button"
        className="btn btn-flex btn-outline text-gray-700 border  btn-active-color-primary bg-state-light flex-center text-nowrap w-100 fs-6 mb-10"
        disabled={googleLoading}
      >
        {googleLoading ? (
          <span className="indicator-progress" style={{ display: 'block' }}>
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        ) : (
          <>
            <img alt="Logo" src="/media/svg/brand-logos/google-icon.svg" className="h-15px me-3" />
            Google ile giriş yap
          </>
        )}
      </button>
      <div className="w-100 d-flex justify-content-center align-items-center">
        <div className="text-gray-500 text-center fw-normal fs-6">Hesabınız yok mu ?</div>
        <Link to="/auth/registration" className="link-primary mx-2 fs-6">
          Yeni Hesap Oluştur
        </Link>
      </div>

      {/* end::Action */}
    </form>
  );
}
