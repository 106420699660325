import React from 'react';

type Props = {};

export default function Maintance({}: Props) {
  return (
    <div className="d-flex flex-column flex-root">
      <div className="d-flex flex-column flex-center flex-column-fluid">
        <div className="d-flex flex-column flex-center text-center p-10">
          <div className="card card-flush  w-lg-650px py-5">
            <div className="card-body py-15 py-lg-15">
              <h1 className="fw-bolder fs-1hx text-gray-900 mb-10">Bakım Çalışması!</h1>
              <div className="fw-normal fs-6 text-gray-700 mb-7 text-start">
                <div className="mb-3">Değerli Ziyaretçilerimiz, </div>
                <div className="mb-3">Web sitemizde güncelleme ve bakım çalışması yapılıyor. Bu süreçte kısa kesintiler olabilir. Anlayışınız için teşekkür ederiz.</div>
                Saygılarımızla,
              </div>
              <div className="mb-10">
                <img src="/media/illustrations/process-3.png" className="mw-100 mh-300px " alt="" />
              </div>
              <div className="mb-0">
                <button className="btn btn-sm btn-light-primary" onClick={() => window.location.reload()}>
                  Sayfayı Yenile
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
