import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import store from '../../app/store';
import { AuthModel } from './models/user-model.interface';

const REACT_APP_API_URL = `${process.env.REACT_APP_API_URL}`;

export interface ITokens {
  access: {
    expires: string;
    token: string;
  };
  refresh: {
    expires: string;
    token: string;
  };
}

const initialState: IAuthState = {
  user: undefined,
  tokens: undefined,
  isAuthorized: false,
  isJoyride: true,
};

export interface IAuthState {
  user?: AuthModel | null | any;
  tokens?: ITokens | null;
  isAuthorized: boolean;
  isJoyride: boolean;
}
export const companyList = createAsyncThunk('company/list', async () => {
  const user_id = store.getState()?.auth?.user?.id;
  const company_id = store.getState()?.auth?.user?.company?.id;
  var params = { user_id, company_id };
  const res = await axios.get<any>(`${REACT_APP_API_URL}/company/list`, { params });
  return res.data;
});

export const companyChange = createAsyncThunk('company/change', async (body: any) => {
  const res = await axios.post<any>(`${REACT_APP_API_URL}/auth/change/branch`, body);
  return res.data;
});

export const userSlice = createSlice({
  name: 'EDuser',
  initialState,
  reducers: {
    _login: (state, action: PayloadAction<IAuthState>) => {
      state.tokens = action.payload.tokens;
      state.user = action.payload.user;
      state.isAuthorized = true;
    },
    _logOut: (state) => {
      state.user = null;
      state.tokens = null;
      state.isAuthorized = false;
    },
    _setStamp: (state, action) => {
      state.user.company.stamp = action.payload;
    },
    _changeCompany: (state, action) => {
      state.user.company = action.payload;
      state.user.company_id = action.payload.id;
    },
    _setJoyride: (state) => {
      state.isJoyride = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(companyList.fulfilled, (state, action) => {
      state.user = action.payload;
    });
    builder.addCase(companyChange.fulfilled, (state, action) => {
      state.tokens = action.payload.tokens;
      state.user = action.payload.user;
      state.isAuthorized = true;
      setTimeout(() => {
        window.location.reload();
      }, 200);
    });
  },
});

export const { _login, _logOut, _setStamp, _changeCompany, _setJoyride } = userSlice.actions;
export default userSlice.reducer;
