import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Formik, Form, FormikValues, FormikProps } from 'formik';
import { StepperComponent } from 'src/_metronic/assets/ts/components';
import { KTSVG, toAbsoluteUrl } from 'src/_metronic/helpers';
import { _login } from 'src/services/redux/slices/auth/auth-slice';
import { login, register } from '../redux/AuthCRUD';
import { ICreateAccount, createAccountSchemas, inits } from './registration/components/CreateAccountWizardHelper';
import { Step1 } from './registration/components/steps/Step1';
import { Step2 } from './registration/components/steps/Step2';
import { Step3 } from './registration/components/steps/Step3';
import { Step4 } from './registration/components/steps/Step4';
import { Step5 } from './registration/components/steps/Step5';

const Registration: FC = () => {
  const dispatch = useDispatch();

  const [loginData, setLoginData] = useState<any>();
  const formikRef = useRef<FormikProps<any>>(null);
  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0]);
  const [initValues] = useState<ICreateAccount>(inits);

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement);
  };

  const prevStep = () => {
    if (!stepper.current) {
      return;
    }

    stepper.current.goPrev();

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1]);
  };

  const handleRegister = async (values: ICreateAccount) => {
    try {
      const body = { ...formikRef.current?.values };
      body.contact_phone = body?.contact_phone?.value;
      const data = await register(body);
      setLoginData(data);
      if (!stepper.current) {
        return;
      }
      stepper.current.goNext();
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };

  const submitStep = (values: ICreateAccount, actions: FormikValues) => {
    if (!stepper.current) {
      return;
    }
    formikRef.current?.setTouched({});
    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex]);

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext();
    } else {
      if (loginData) dispatch(_login(loginData));
    }
  };

  useEffect(() => {
    if (!stepperRef.current) {
      return;
    }
    loadStepper();
  }, [stepperRef]);

  return (
    <div className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">
      {/*begin::Heading*/}
      <div className="text-center mb-11">
        {/*begin::Title*/}
        <h1 className="text-gray-900 fw-bolder mb-3">Kayıt Ol</h1>
        {/*end::Title*/}
        {/*begin::Subtitle*/}
        <div className="text-gray-500 fw-normal fs-6">Yeni kayıt olan kurumlar için bir aylık ücretsiz eğitim kurumu kullanımı sağlanmaktadır.</div>
        {/*end::Subtitle-*/}
      </div>
      {/*begin::Heading*/}
      <div ref={stepperRef} className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid py-10" id="kt_create_account_stepper">
        <div className="d-flex flex-row-auto">
          <div className="px-6 px-lg-10 px-xxl-5 py-5">
            <div className="stepper-nav">
              <div className="stepper-item current" data-kt-stepper-element="nav">
                <div className="stepper-line w-40px"></div>

                <div className="stepper-icon w-40px h-40px">
                  <KTSVG path="/media/icons/duotone/Navigation/Check.svg" className="stepper-check svg-icon-2" />
                  <span className="stepper-number">1</span>
                </div>

                <div className="stepper-label">
                  <h3 className="stepper-title">Hesap Bilgileri</h3>

                  <div className="stepper-desc fw-bold">Hesap bilgilerinizi tanımlayın</div>
                </div>
              </div>

              <div className="stepper-item" data-kt-stepper-element="nav">
                <div className="stepper-line w-40px"></div>

                <div className="stepper-icon w-40px h-40px">
                  <KTSVG path="/media/icons/duotone/Navigation/Check.svg" className="stepper-check svg-icon-2" />
                  <span className="stepper-number">2</span>
                </div>

                <div className="stepper-label">
                  <h3 className="stepper-title">Kurum Bilgileri</h3>
                  <div className="stepper-desc fw-normal">Kurum bilgilerinizi tanımlayın</div>
                </div>
              </div>

              <div className="stepper-item" data-kt-stepper-element="nav">
                <div className="stepper-line w-40px"></div>

                <div className="stepper-icon w-40px h-40px">
                  <KTSVG path="/media/icons/duotone/Navigation/Check.svg" className="stepper-check svg-icon-2" />
                  <span className="stepper-number">3</span>
                </div>

                <div className="stepper-label">
                  <h3 className="stepper-title">Web Site Bilgisi</h3>
                  <div className="stepper-desc fw-normal">Web sitesi yönlendirme bilgileri</div>
                </div>
              </div>

              <div className="stepper-item" data-kt-stepper-element="nav">
                <div className="stepper-line w-40px"></div>

                <div className="stepper-icon w-40px h-40px">
                  <KTSVG path="/media/icons/duotone/Navigation/Check.svg" className="stepper-check svg-icon-2" />
                  <span className="stepper-number">4</span>
                </div>

                <div className="stepper-label">
                  <h3 className="stepper-title">Şifre Tanımla</h3>
                  <div className="stepper-desc fw-normal">Şifre bilgilerini tanımla</div>
                </div>
              </div>

              <div className="stepper-item" data-kt-stepper-element="nav">
                <div className="stepper-line w-40px"></div>

                <div className="stepper-icon w-40px h-40px">
                  <KTSVG path="/media/icons/duotone/Navigation/Check.svg" className="stepper-check svg-icon-2" />
                  <span className="stepper-number">5</span>
                </div>

                <div className="stepper-label">
                  <h3 className="stepper-title">Hazırsınız</h3>
                  <div className="stepper-desc fw-normal">Tüm işlemler tamamlandı</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex flex-row-fluid flex-center bg-white rounded">
          <Formik
            innerRef={formikRef}
            validationSchema={currentSchema}
            initialValues={initValues}
            onSubmit={stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber! - 1 ? handleRegister : submitStep}
          >
            {() => (
              <Form className="px-3 mx-3 " noValidate id="kt_create_account_form">
                <div className="current" data-kt-stepper-element="content">
                  <Step1 />
                </div>

                <div data-kt-stepper-element="content">
                  <Step2 />
                </div>

                <div data-kt-stepper-element="content">
                  <Step3 />
                </div>

                <div data-kt-stepper-element="content">
                  <Step4 />
                </div>

                <div data-kt-stepper-element="content">
                  <Step5 />
                </div>

                <div className="d-flex flex-stack pt-10">
                  <div className="mr-2">
                    {stepper.current?.currentStepIndex !== 5 && (
                      <button onClick={prevStep} type="button" className="btn btn-light me-3" data-kt-stepper-action="previous">
                        <KTSVG path="/media/icons/duotone/Navigation/Left-2.svg" className="svg-icon-4 me-1" />
                        Geri
                      </button>
                    )}
                  </div>

                  <div>
                    <button type="submit" className="btn btn-light-primary me-3">
                      <span className="indicator-label">
                        {stepper.current?.currentStepIndex !== stepper.current?.totatStepsNumber! - 1 && 'İlerle'}
                        {stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber! - 1 && 'Kaydol'}
                        <KTSVG path="/media/icons/duotone/Navigation/Right-2.svg" className="svg-icon-3 ms-2 me-0" />
                      </span>
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export { Registration };
