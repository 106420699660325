/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import { ForgotPassword } from './components/ForgotPassword';
import { Login } from './components/Login';
import { Registration } from './components/Registration';
import { TwoFactor } from './components/TwoFactor';

export function AuthPage() {
  useEffect(() => {
    document.body.classList.add('bg-white');
    return () => {
      document.body.classList.remove('bg-white');
    };
  }, []);

  return (
    <div
      className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-fixed bgi-attachment-fixed"
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/bg10.jpg')})`,
      }}
    >
      <div className="d-xl-flex flex-column flex-lg-row flex-column-fluid">
        {/*begin::Aside*/}
        <div className="d-flex flex-lg-row-fluid">
          {/*begin::Content*/}
          <div className="d-flex flex-column flex-center pb-0 pb-lg-10 p-10 w-100">
            {/*begin::Image*/}
            <img className="theme-light-show mx-auto mw-100 w-150px w-lg-350px mb-10 mb-lg-20" src="/media/illustrations/agency.png" alt="" />
            {/*end::Image*/}
            {/*begin::Title*/}
            <h1 className="text-gray-800 fs-2qx fw-bolder text-center mb-7">Öğrenmeye Yeniden Bakış</h1>
            {/*end::Title*/}
            {/*begin::Text*/}
            <div className="text-gray-600 fs-6 text-center fw-normal px-10">
              Eduthon, öğrenci-öğretmen iletişimini güçlendirerek kişiselleştirilmiş öğrenme deneyimleri ve verimli eğitim süreçleri sunar, modern eğitimde önemli bir rol oynar.
            </div>
            {/*end::Text*/}
          </div>
          {/*end::Content*/}
        </div>
        {/*begin::Aside*/}
        <div className="d-xl-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12">
          {/*begin::Wrapper*/}
          <div className="bg-body d-flex flex-column rounded-4 w-xl-800px p-5">
            {/*begin::Content*/}
            <div className="d-flex flex-center flex-column align-items-stretch h-lg-100 ">
              {/*begin::Wrapper*/}
              <div className="d-flex flex-center flex-column flex-column-fluid pb-15 pb-lg-20">
                <Switch>
                  <Route path="/auth/login" component={Login} />
                  <Route path="/auth/registration" component={Registration} />
                  <Route path="/auth/forgot-password" component={ForgotPassword} />
                  <Route path="/auth/two-factor" component={TwoFactor} />
                  <Redirect from="/auth" exact={true} to="/auth/login" />
                  <Redirect to="/auth/login" />
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
