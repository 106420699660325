//@ts-ignore
import ReactHtmlParser from 'react-html-parser';

const CancelRefund = () => {
  const html = `
  <div class="appie-visible active " style="padding:30px;">
  <div class="post-item-content">
      <p align="justify" style="margin-left: 0.25in; margin-bottom: 0in; line-height: 100%;"><br></p>
      <p align="justify" style="margin-left: 0.25in; margin-bottom: 0in; line-height: 100%;"><span
              style="font-weight: bold;">TÜKETİCİ HAKLARI – CAYMA – İPTAL İADE KOŞULLARI</span></p>
      <p class="western" align="justify" style="margin-bottom: 0in; line-height: 100%;"><br></p>
      <p align="justify" style="margin-left: 0.25in; margin-bottom: 0in; line-height: 100%;"><span
              style="font-weight: bold;">GENEL:</span></p>
      <ol>
          <li>
              <p align="justify" style="margin-bottom: 0in; line-height: 100%;">Kullanmakta olduğunuz web sitesi
                  üzerinden elektronik ortamda
                  sipariş verdiğiniz takdirde, size sunulan ön bilgilendirme
                  formunu ve mesafeli satış sözleşmesini kabul etmiş
                  sayılırsınız.</p>
          </li>
          <li>
              <p align="justify" style="margin-bottom: 0in; line-height: 100%;">Alıcılar, satın aldıkları ürünün satış
                  ve teslimi ile
                  ilgili olarak 6502 sayılı Tüketicinin Korunması Hakkında Kanun
                  ve Mesafeli Sözleşmeler Yönetmeliği (RG:27.11.2014/29188)
                  hükümleri ile yürürlükteki diğer yasalara tabidir.</p>
          </li>
          <li>
              <p align="justify" style="margin-bottom: 0in; line-height: 100%;">Ürün sevkiyat masrafı olan kargo
                  ücretleri alıcılar tarafından
                  ödenecektir.</p>
          </li>
          <li>
              <p align="justify" style="margin-bottom: 0in; line-height: 100%;">Satın alınan her bir ürün, 30 günlük
                  yasal süreyi aşmamak
                  kaydı ile alıcının gösterdiği adresteki kişi ve/veya
                  kuruluşa teslim edilir. Bu süre içinde ürün teslim edilmez ise,
                  Alıcılar sözleşmeyi sona erdirebilir.</p>
          </li>
          <li>
              <p align="justify" style="margin-bottom: 0in; line-height: 100%;">Satın alınan ürün, eksiksiz ve
                  siparişte belirtilen niteliklere
                  uygun ve varsa garanti belgesi, kullanım klavuzu gibi belgelerle
                  teslim edilmek zorundadır.</p>
          </li>
          <li>
              <p align="justify" style="margin-bottom: 0in; line-height: 100%;">Satın alınan ürünün satılmasının
                  imkansızlaşması
                  durumunda, satıcı bu durumu öğrendiğinden itibaren 3 gün
                  içinde yazılı olarak alıcıya bu durumu bildirmek zorundadır.
                  14 gün içinde de toplam bedel Alıcı’ya iade edilmek
                  zorundadır.</p>
          </li>
      </ol>
      <p align="justify" style="margin-left: 0.25in; margin-bottom: 0in; line-height: 100%;"><br></p>
      <p align="justify" style="margin-left: 0.25in; margin-bottom: 0in; line-height: 100%;"><span
              style="font-weight: bold;">SATIN ALINAN ÜRÜN BEDELİ ÖDENMEZ İSE: </span></p>
      <ol start="7">
          <li>
              <p align="justify" style="margin-bottom: 0in; line-height: 100%;">Alıcı, satın aldığı ürün bedelini
                  ödemez veya banka
                  kayıtlarında iptal ederse, Satıcının ürünü teslim
                  yükümlülüğü sona erer.</p>
          </li>
      </ol>
      <p align="justify" style="margin-left: 0.25in; margin-bottom: 0in; line-height: 100%;"><br></p>
      <p align="justify" style="margin-left: 0.25in; margin-bottom: 0in; line-height: 100%;"><span
              style="font-weight: bold;">KREDİ KARTININ YETKİSİZ KULLANIMI İLE YAPILAN ALIŞVERİŞLER:</span></p>
      <ol start="8">
          <li>
              <p align="justify" style="margin-bottom: 0in; line-height: 100%;">Ürün teslim edildikten sonra, alıcının
                  ödeme yaptığı kredi
                  kartının yetkisiz kişiler tarafından haksız olarak kullanıldığı
                  tespit edilirse ve satılan ürün bedeli ilgili banka veya finans
                  kuruluşu tarafından Satıcı'ya ödenmez ise, Alıcı, sözleşme
                  konusu ürünü 3 gün içerisinde nakliye gideri SATICI’ya ait
                  olacak şekilde SATICI’ya iade etmek zorundadır.</p>
          </li>
      </ol>
      <p align="justify" style="margin-left: 0.25in; margin-bottom: 0in; line-height: 100%;"><br></p>
      <p align="justify" style="margin-left: 0.25in; margin-bottom: 0in; line-height: 100%;"><span
              style="font-weight: bold;">ÖNGÖRÜLEMEYEN SEBEPLERLE ÜRÜN SÜRESİNDE TESLİM EDİLEMEZ
              İSE: </span></p>
      <ol start="9">
          <li>
              <p align="justify" style="margin-bottom: 0in; line-height: 100%;">Satıcı’nın öngöremeyeceği mücbir
                  sebepler oluşursa ve ürün
                  süresinde teslim edilemez ise, durum Alıcı’ya bildirilir.
                  Alıcı, siparişin iptalini, ürünün benzeri ile değiştirilmesini
                  veya engel ortadan kalkana dek teslimatın ertelenmesini talep
                  edebilir. Alıcı siparişi iptal ederse; ödemeyi nakit ile yapmış
                  ise iptalinden itibaren 14 gün içinde kendisine nakden bu ücret
                  ödenir. Alıcı, ödemeyi kredi kartı ile yapmış ise ve iptal
                  ederse, bu iptalden itibaren yine 14 gün içinde ürün bedeli
                  bankaya iade edilir, ancak bankanın alıcının hesabına 2-3 hafta
                  içerisinde aktarması olasıdır.</p>
          </li>
      </ol>
      <p align="justify" style="margin-left: 0.25in; margin-bottom: 0in; line-height: 100%;"><br></p>
      <p align="justify" style="margin-left: 0.25in; margin-bottom: 0in; line-height: 100%;"><span
              style="font-weight: bold;">ALICININ ÜRÜNÜ KONTROL ETME YÜKÜMLÜLÜĞÜ: </span></p>
      <ol start="10">
          <li>
              <p align="justify" style="margin-bottom: 0in; line-height: 100%;">Alıcı, sözleşme konusu mal/hizmeti
                  teslim almadan önce muayene
                  edecek; ezik, kırık, ambalajı yırtılmış vb. hasarlı ve
                  ayıplı mal/hizmeti kargo şirketinden teslim almayacaktır. Teslim
                  alınan mal/hizmetin hasarsız ve sağlam olduğu kabul edilecektir.
                  ALICI , Teslimden sonra mal/hizmeti özenle korunmak zorundadır.
                  Cayma hakkı kullanılacaksa mal/hizmet kullanılmamalıdır. Ürünle
                  birlikte Fatura da iade edilmelidir.</p>
          </li>
      </ol>
      <p align="justify" style="margin-left: 0.25in; margin-bottom: 0in; line-height: 100%;"><br></p>
      <p align="justify" style="margin-left: 0.25in; margin-bottom: 0in; line-height: 100%;"><span
              style="font-weight: bold;">CAYMA HAKKI:</span></p>
      <ol start="11">
          <li>
              <p align="justify" style="margin-bottom: 0in; line-height: 100%;">ALICI; satın aldığı ürünün kendisine
                  veya gösterdiği
                  adresteki kişi/kuruluşa teslim tarihinden itibaren 14 (on dört)
                  gün içerisinde, SATICI’ya aşağıdaki iletişim bilgileri
                  üzerinden bildirmek şartıyla hiçbir hukuki ve cezai sorumluluk
                  üstlenmeksizin ve hiçbir gerekçe göstermeksizin malı reddederek
                  sözleşmeden cayma hakkını kullanabilir.</p>
          </li>
      </ol>
      <p align="justify" style="margin-left: 0.25in; margin-bottom: 0in; line-height: 100%;"><br></p>
      <ol start="12">
          <li>
              <p align="justify" style="margin-bottom: 0in; line-height: 100%;"><span
                      style="font-weight: bold;">SATICININ CAYMA HAKKI BİLDİRİMİ YAPILACAK İLETİŞİM
                      BİLGİLERİ:</span></p>
          </li>
      </ol>
      <p align="justify" style="margin-left: 0.25in; margin-bottom: 0in; line-height: 100%;">ŞİRKET</p>
      <p align="justify" style="margin-left: 0.25in; margin-bottom: 0in; line-height: 100%;">ADI/UNVANI: Apithon Software<br>
          ADRES:  Atatürk
        Üniversitesi Yerleşkesi İpekyolu Caddesi Teknoloji Geliştirme Bölgesi B
        Blok No:305 Pk. 25240 Yakutiye ERZURUM<br> EPOSTA: <a href="mailto:bilgi@apithon.com.tr">bilgi@apithon.com.tr</a><br></p>
      <p align="justify" style="margin-left: 0.25in; margin-bottom: 0in; line-height: 100%;"><br></p>
      <p align="justify" style="margin-left: 0.25in; margin-bottom: 0in; line-height: 100%;"><span
              style="font-weight: bold;">CAYMA HAKKININ SÜRESİ:</span></p>
      <ol start="13">
          <li>
              <p align="justify" style="margin-bottom: 0in; line-height: 100%;">Alıcı, satın aldığı eğer bir hizmet
                  ise, bu 14 günlük süre
                  sözleşmenin imzalandığı tarihten itibaren başlar. Cayma hakkı
                  süresi sona ermeden önce, tüketicinin onayı ile hizmetin ifasına
                  başlanan hizmet sözleşmelerinde cayma hakkı kullanılamaz.</p>
          </li>
          <li>
              <p align="justify" style="margin-bottom: 0in; line-height: 100%;">Cayma hakkının kullanımından
                  kaynaklanan masraflar SATICI’ ya
                  aittir.</p>
          </li>
          <li>
              <p align="justify" style="margin-bottom: 0in; line-height: 100%;">Cayma hakkının kullanılması için 14
                  (ondört) günlük süre
                  içinde SATICI' ya iadeli taahhütlü posta, faks veya eposta ile
                  yazılı bildirimde bulunulması ve ürünün işbu sözleşmede
                  düzenlenen "Cayma Hakkı Kullanılamayacak Ürünler"
                  hükümleri çerçevesinde kullanılmamış olması şarttır.</p>
          </li>
      </ol>
      <p align="justify" style="margin-left: 0.25in; margin-bottom: 0in; line-height: 100%;"><br></p>
      <p align="justify" style="margin-left: 0.25in; margin-bottom: 0in; line-height: 100%;"><span
              style="font-weight: bold;">CAYMA HAKKININ KULLANIMI:&nbsp;</span></p>
      <ol start="16">
          <li>
              <p align="justify" style="margin-bottom: 0in; line-height: 100%;">3. kişiye veya ALICI’ ya teslim edilen
                  ürünün faturası, (İade
                  edilmek istenen ürünün faturası kurumsal ise, iade ederken
                  kurumun düzenlemiş olduğu iade faturası ile birlikte
                  gönderilmesi gerekmektedir. Faturası kurumlar adına düzenlenen
                  sipariş iadeleri İADE FATURASI kesilmediği takdirde
                  tamamlanamayacaktır.)</p>
          </li>
          <li>
              <p align="justify" style="margin-bottom: 0in; line-height: 100%;">İade formu, İade edilecek ürünlerin
                  kutusu, ambalajı, varsa
                  standart aksesuarları ile birlikte eksiksiz ve hasarsız olarak
                  teslim edilmesi gerekmektedir.</p>
          </li>
      </ol>
      <p align="justify" style="margin-left: 0.25in; margin-bottom: 0in; line-height: 100%;"><br></p>
      <p align="justify" style="margin-left: 0.25in; margin-bottom: 0in; line-height: 100%;"><span
              style="font-weight: bold;">İADE KOŞULLARI:</span></p>
      <ol start="18">
          <li>
              <p align="justify" style="margin-bottom: 0in; line-height: 100%;">SATICI, cayma bildiriminin kendisine
                  ulaşmasından itibaren en geç
                  10 günlük süre içerisinde toplam bedeli ve ALICI’yı borç
                  altına sokan belgeleri ALICI’ ya iade etmek ve 20 günlük süre
                  içerisinde malı iade almakla yükümlüdür.</p>
          </li>
          <li>
              <p align="justify" style="margin-bottom: 0in; line-height: 100%;">ALICI’ nın kusurundan kaynaklanan bir
                  nedenle malın değerinde
                  bir azalma olursa veya iade imkânsızlaşırsa ALICI kusuru
                  oranında SATICI’ nın zararlarını tazmin etmekle yükümlüdür.
                  Ancak cayma hakkı süresi içinde malın veya ürünün usulüne
                  uygun kullanılması sebebiyle meydana gelen değişiklik ve
                  bozulmalardan ALICI sorumlu değildir.&nbsp;</p>
          </li>
          <li>
              <p align="justify" style="margin-bottom: 0in; line-height: 100%;">Cayma hakkının kullanılması nedeniyle
                  SATICI tarafından
                  düzenlenen kampanya limit tutarının altına düşülmesi halinde
                  kampanya kapsamında faydalanılan indirim miktarı iptal edilir.</p>
          </li>
      </ol>
      <p align="justify" style="margin-left: 0.25in; margin-bottom: 0in; line-height: 100%;"><br></p>
      <p align="justify" style="margin-left: 0.25in; margin-bottom: 0in; line-height: 100%;"><span
              style="font-weight: bold;">CAYMA HAKKI KULLANILAMAYACAK ÜRÜNLER: </span></p>
      <ol start="21">
          <li>
              <p align="justify" style="margin-bottom: 0in; line-height: 100%;">ALICI’nın isteği veya açıkça kişisel
                  ihtiyaçları
                  doğrultusunda hazırlanan ve geri gönderilmeye müsait olmayan, iç
                  giyim alt parçaları, mayo ve bikini altları, makyaj malzemeleri,
                  tek kullanımlık ürünler, çabuk bozulma tehlikesi olan veya son
                  kullanma tarihi geçme ihtimali olan mallar, ALICI’ya teslim
                  edilmesinin ardından ALICI tarafından ambalajı açıldığı
                  takdirde iade edilmesi sağlık ve hijyen açısından uygun olmayan
                  ürünler, teslim edildikten sonra başka ürünlerle karışan ve
                  doğası gereği ayrıştırılması mümkün olmayan ürünler,
                  Abonelik sözleşmesi kapsamında sağlananlar dışında, gazete ve
                  dergi gibi süreli yayınlara ilişkin mallar, Elektronik ortamda
                  anında ifa edilen hizmetler veya tüketiciye anında teslim
                  edilen&nbsp;gayrimaddi&nbsp;mallar, ile ses veya görüntü
                  kayıtlarının, kitap, dijital içerik, yazılım programlarının,
                  veri kaydedebilme ve veri depolama cihazlarının, bilgisayar sarf
                  malzemelerinin, ambalajının ALICI tarafından açılmış olması
                  halinde iadesi Yönetmelik gereği mümkün değildir. Ayrıca Cayma
                  hakkı süresi sona ermeden önce, tüketicinin onayı ile ifasına
                  başlanan hizmetlere ilişkin cayma hakkının kullanılması da
                  Yönetmelik gereği mümkün değildir.</p>
          </li>
          <li>
              <p align="justify" style="margin-bottom: 0in; line-height: 100%;">Kozmetik ve kişisel bakım ürünleri, iç
                  giyim ürünleri, mayo,
                  bikini, kitap, kopyalanabilir yazılım ve programlar, DVD, VCD, CD
                  ve kasetler ile kırtasiye sarf malzemeleri (toner, kartuş, şerit
                  vb.) iade edilebilmesi için ambalajlarının açılmamış,
                  denenmemiş, bozulmamış ve kullanılmamış olmaları gerekir.</p>
          </li>
      </ol>
      <p align="justify" style="margin-left: 0.25in; margin-bottom: 0in; line-height: 100%;"><br></p>
      <p align="justify" style="margin-left: 0.25in; margin-bottom: 0in; line-height: 100%;"><span
              style="font-weight: bold;">TEMERRÜT HALİ VE HUKUKİ SONUÇLARI</span></p>
      <ol start="23">
          <li>
              <p align="justify" style="margin-bottom: 0in; line-height: 100%;">ALICI, ödeme işlemlerini kredi kartı
                  ile yaptığı durumda
                  temerrüde düştüğü takdirde, kart sahibi banka ile arasındaki
                  kredi kartı sözleşmesi çerçevesinde faiz ödeyeceğini ve
                  bankaya karşı sorumlu olacağını kabul, beyan ve taahhüt eder.
                  Bu durumda ilgili banka hukuki yollara başvurabilir; doğacak
                  masrafları ve vekâlet ücretini ALICI’dan talep edebilir ve her
                  koşulda ALICI’nın borcundan dolayı temerrüde düşmesi
                  halinde, ALICI, borcun gecikmeli ifasından dolayı SATICI’nın
                  uğradığı zarar ve ziyanını ödeyeceğini kabul eder.</p>
          </li>
      </ol>
      <p class="western" align="justify" style="margin-bottom: 0in; line-height: 100%;"><br></p>
      <p align="justify" style="margin-left: 0.25in; margin-bottom: 0in; line-height: 100%;"><span
              style="font-weight: bold;">ÖDEME VE TESLİMAT</span></p>
      <ol start="24">
         
          <li>
              <p align="justify" style="margin-bottom: 0in; line-height: 100%;">Sitemiz üzerinden kredi kartlarınız
                  ile, Her türlü kredi
                  kartınıza online tek ödeme ya da&nbsp;online taksit imkânlarından
                  yararlanabilirsiniz. Online ödemelerinizde siparişiniz sonunda
                  kredi kartınızdan tutar çekim işlemi gerçekleşecektir.<br> <br></p>
          </li>
      </ol>
      <p class="western" align="justify" style="margin-bottom: 0in; line-height: 100%;"><br></p>
  </div>
</div>`;

  return <>{ReactHtmlParser(html)}</>;
};
export default CancelRefund;
