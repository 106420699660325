const PrivacyAndSecurity = () => (
  <>
    <div style={{ padding: 30 }}>
      <div>
        <br />
      </div>
      <b>GİZLİLİK VE GÜVENLİK POLİTİKASI</b>
      <div>&nbsp;</div>
      <div>Mağazamızda verilen tüm servisler ve , eduthon.apithon.com.tr adresinde kayıtlı &nbsp;Apithon Software. firmamıza aittir ve firmamız tarafından işletilir.&nbsp;</div>
      <div>
        <br />
      </div>
      <div>
        Firmamız, çeşitli amaçlarla kişisel veriler toplayabilir. Aşağıda, toplanan kişisel verilerin nasıl ve ne şekilde toplandığı, bu verilerin nasıl ve ne şekilde korunduğu
        belirtilmiştir.&nbsp;
      </div>
      <div>
        <br />
      </div>
      <div>
        Üyelik veya Mağazamız üzerindeki çeşitli form ve anketlerin doldurulması suretiyle üyelerin kendileriyle ilgili bir takım kişisel bilgileri (isim-soy isim, firma bilgileri,
        telefon, adres veya e-posta adresleri gibi) Mağazamız tarafından işin doğası gereği toplanmaktadır.&nbsp;
      </div>
      <div>
        <br />
      </div>
      <div>
        Firmamız bazı dönemlerde müşterilerine ve üyelerine kampanya bilgileri, yeni ürünler hakkında bilgiler, promosyon teklifleri gönderebilir. Üyelerimiz bu gibi bilgileri alıp
        almama konusunda her türlü seçimi üye olurken yapabilir, sonrasında üye girişi yaptıktan sonra hesap bilgileri bölümünden bu seçimi değiştirilebilir ya da kendisine gelen
        bilgilendirme iletisindeki linkle bildirim yapabilir.&nbsp;
      </div>
      <div>
        <br />
      </div>
      <div>
        Mağazamız üzerinden veya eposta ile gerçekleştirilen onay sürecinde, üyelerimiz tarafından mağazamıza elektronik ortamdan iletilen kişisel bilgiler, Üyelerimiz ile
        yaptığımız "Kullanıcı Sözleşmesi" ile belirlenen amaçlar ve kapsam dışında üçüncü kişilere açıklanmayacaktır.
      </div>
      <div>
        <br />
      </div>
      <div>
        Sistemle ilgili sorunların tanımlanması ve verilen hizmet ile ilgili çıkabilecek sorunların veya uyuşmazlıkların hızla çözülmesi için, Firmamız, üyelerinin IP adresini
        kaydetmekte ve bunu kullanmaktadır. IP adresleri, kullanıcıları genel bir şekilde tanımlamak ve kapsamlı demografik bilgi toplamak amacıyla da kullanılabilir.
      </div>
      <div>
        <br />
      </div>
      <div>
        Firmamız, Üyelik Sözleşmesi ile belirlenen amaçlar ve kapsam dışında da, talep edilen bilgileri kendisi veya işbirliği içinde olduğu kişiler tarafından doğrudan pazarlama
        yapmak amacıyla kullanabilir. &nbsp;Kişisel bilgiler, gerektiğinde kullanıcıyla temas kurmak için de kullanılabilir. Firmamız tarafından talep edilen bilgiler veya
        kullanıcı tarafından sağlanan bilgiler veya Mağazamız üzerinden yapılan işlemlerle ilgili bilgiler; Firmamız ve işbirliği içinde olduğu kişiler tarafından, "Üyelik
        Sözleşmesi" ile belirlenen amaçlar ve kapsam dışında da, üyelerimizin kimliği ifşa edilmeden çeşitli istatistiksel değerlendirmeler, veri tabanı oluşturma ve pazar
        araştırmalarında kullanılabilir.
      </div>
      <div>
        <br />
      </div>
      <div>
        Firmamız, gizli bilgileri kesinlikle özel ve gizli tutmayı, bunu bir sır saklama yükümü olarak addetmeyi ve gizliliğin sağlanması ve sürdürülmesi, gizli bilginin tamamının
        veya herhangi bir kısmının kamu alanına girmesini veya yetkisiz kullanımını veya üçüncü bir kişiye ifşasını önlemek için gerekli tüm tedbirleri almayı ve gerekli özeni
        göstermeyi taahhüt etmektedir.
      </div>
      <div>&nbsp;</div>
      <b>KREDİ KARTI GÜVENLİĞİ</b>
      <div>&nbsp;</div>
      <div>
        Firmamız, alışveriş sitelerimizden alışveriş yapan kredi kartı sahiplerinin güvenliğini ilk planda tutmaktadır. Kredi kartı bilgileriniz hiçbir şekilde sistemimizde
        saklanmamaktadır.
      </div>
      <div>&nbsp;</div>
      <div>
        İşlemler sürecine girdiğinizde güvenli bir sitede olduğunuzu anlamak için dikkat etmeniz gereken iki şey vardır. Bunlardan biri tarayıcınızın en alt satırında bulunan bir
        anahtar ya da kilit simgesidir. Bu güvenli bir internet sayfasında olduğunuzu gösterir ve her türlü bilgileriniz şifrelenerek korunur. Bu bilgiler, ancak satış işlemleri
        sürecine bağlı olarak ve verdiğiniz talimat istikametinde kullanılır. Alışveriş sırasında kullanılan kredi kartı ile ilgili bilgiler alışveriş sitelerimizden bağımsız
        olarak 128 bit SSL (Secure Sockets Layer) protokolü ile şifrelenip sorgulanmak üzere ilgili bankaya ulaştırılır. Kartın kullanılabilirliği onaylandığı takdirde alışverişe
        devam edilir. Kartla ilgili hiçbir bilgi tarafımızdan görüntülenemediğinden ve kaydedilmediğinden, üçüncü şahısların herhangi bir koşulda bu bilgileri ele geçirmesi
        engellenmiş olur.
      </div>
      <div>
        Online olarak kredi kartı ile verilen siparişlerin ödeme/fatura/teslimat adresi bilgilerinin güvenilirliği firmamiz tarafından Kredi Kartları Dolandırıcılığı'na karşı
        denetlenmektedir. Bu yüzden, alışveriş sitelerimizden ilk defa sipariş veren müşterilerin siparişlerinin tedarik ve teslimat aşamasına gelebilmesi için öncelikle finansal
        ve adres/telefon bilgilerinin doğruluğunun onaylanması gereklidir. Bu bilgilerin kontrolü için gerekirse kredi kartı sahibi müşteri ile veya ilgili banka ile irtibata
        geçilmektedir.
      </div>
      <div>
        Üye olurken verdiğiniz tüm bilgilere sadece siz ulaşabilir ve siz değiştirebilirsiniz. Üye giriş bilgilerinizi güvenli koruduğunuz takdirde başkalarının sizinle ilgili
        bilgilere ulaşması ve bunları değiştirmesi mümkün değildir. Bu amaçla, üyelik işlemleri sırasında 128 bit SSL güvenlik alanı içinde hareket edilir. Bu sistem kırılması
        mümkün olmayan bir uluslararası bir şifreleme standardıdır.
      </div>
      <div>
        <br />
      </div>
      <div>
        Bilgi hattı veya müşteri hizmetleri servisi bulunan ve açık adres ve telefon bilgilerinin belirtildiği İnternet alışveriş siteleri günümüzde daha fazla tercih edilmektedir.
        Bu sayede aklınıza takılan bütün konular hakkında detaylı bilgi alabilir, online alışveriş hizmeti sağlayan firmanın güvenirliği konusunda daha sağlıklı bilgi
        edinebilirsiniz.&nbsp;
      </div>
      <div>&nbsp;</div>
      <div>
        Not: İnternet alışveriş sitelerinde firmanın açık adresinin ve telefonun yer almasına dikkat edilmesini tavsiye ediyoruz. Alışveriş yapacaksanız alışverişinizi yapmadan
        ürünü aldığınız mağazanın bütün telefon / adres bilgilerini not edin. Eğer güvenmiyorsanız alışverişten önce telefon ederek teyit edin. Firmamıza ait tüm online alışveriş
        sitelerimizde firmamıza dair tüm bilgiler ve firma yeri belirtilmiştir.
      </div>
      <div>&nbsp;</div>
      <b>MAİL ORDER KREDİ KART BİLGİLERİ GÜVENLİĞİ</b>
      <div>&nbsp;</div>
      <div>
        Kredi kartı mail-order yöntemi ile bize göndereceğiniz kimlik ve kredi kart bilgileriniz firmamız tarafından gizlilik prensibine göre saklanacaktır. Bu bilgiler olası banka
        ile oluşubilecek kredi kartından para çekim itirazlarına karşı 60 gün süre ile bekletilip daha sonrasında imha edilmektedir. Sipariş ettiğiniz ürünlerin bedeli karşılığında
        bize göndereceğiniz tarafınızdan onaylı mail-order formu bedeli dışında herhangi bir bedelin kartınızdan çekilmesi halinde doğal olarak bankaya itiraz edebilir ve bu
        tutarın ödenmesini engelleyebileceğiniz için bir risk oluşturmamaktadır.&nbsp;
      </div>
      <div>
        <br />
      </div>
      <div>
        <br />
      </div>
      <b>ÜÇÜNCÜ TARAF WEB SİTELERİ VE UYGULAMALAR</b>
      <div>
        Mağazamız, &nbsp;web sitesi dâhilinde başka sitelere link verebilir. Firmamız, bu linkler vasıtasıyla erişilen sitelerin gizlilik uygulamaları ve içeriklerine yönelik
        herhangi bir sorumluluk taşımamaktadır. Firmamıza ait sitede yayınlanan reklamlar, reklamcılık yapan iş ortaklarımız aracılığı ile kullanıcılarımıza dağıtılır. İş bu
        sözleşmedeki Gizlilik Politikası Prensipleri, sadece Mağazamızın kullanımına ilişkindir, üçüncü taraf web sitelerini kapsamaz.&nbsp;
      </div>
      <div>
        <br />
      </div>
      <b>İSTİSNAİ HALLER</b>
      <div>
        Aşağıda belirtilen sınırlı hallerde Firmamız, işbu "Gizlilik Politikası" hükümleri dışında kullanıcılara ait bilgileri üçüncü kişilere açıklayabilir. Bu durumlar sınırlı
        sayıda olmak üzere;
      </div>
      <div>
        1.<span className="Apple-tab-span"></span>Kanun, Kanun Hükmünde Kararname, Yönetmelik v.b. yetkili hukuki otorite tarafından çıkarılan ve yürürlülükte olan hukuk
        kurallarının getirdiği zorunluluklara uymak;
      </div>
      <div>
        2.<span className="Apple-tab-span"></span>Mağazamızın kullanıcılarla akdettiği "Üyelik Sözleşmesi"'nin ve diğer sözleşmelerin gereklerini yerine getirmek ve bunları
        uygulamaya koymak amacıyla;
      </div>
      <div>
        3.<span className="Apple-tab-span"></span>Yetkili idari ve adli otorite tarafından usulüne göre yürütülen bir araştırma veya soruşturmanın yürütümü amacıyla kullanıcılarla
        ilgili bilgi talep edilmesi;
      </div>
      <div>
        4.<span className="Apple-tab-span"></span>Kullanıcıların hakları veya güvenliklerini korumak için bilgi vermenin gerekli olduğu hallerdir.&nbsp;
      </div>
      <div>
        <br />
      </div>
      <b>E-POSTA GÜVENLİĞİ</b>
      <div>
        Mağazamızın Müşteri Hizmetleri’ne, herhangi bir siparişinizle ilgili olarak göndereceğiniz e-postalarda, asla kredi kartı numaranızı veya şifrelerinizi yazmayınız.
        E-postalarda yer alan bilgiler üçüncü şahıslar tarafından görülebilir. Firmamız e-postalarınızdan aktarılan bilgilerin güvenliğini hiçbir koşulda garanti edemez.
      </div>
      <div>
        <br />
      </div>
      <b>TARAYICI ÇEREZLERİ&nbsp;</b>
      <div>
        Firmamız, mağazamızı ziyaret eden kullanıcılar ve kullanıcıların web sitesini kullanımı hakkındaki bilgileri teknik bir iletişim dosyası (Çerez-Cookie) kullanarak elde
        edebilir. Bahsi geçen teknik iletişim dosyaları, ana bellekte saklanmak üzere bir internet sitesinin kullanıcının tarayıcısına (browser) gönderdiği küçük metin
        dosyalarıdır. Teknik iletişim dosyası site hakkında durum ve tercihleri saklayarak İnternet'in kullanımını kolaylaştırır.
      </div>
      <div>
        <br />
      </div>
      <div>
        Teknik iletişim dosyası, &nbsp;siteyi kaç kişinin ziyaret ettiğini, bir kişinin siteyi hangi amaçla, kaç kez ziyaret ettiğini ve ne kadar sitede kaldıkları hakkında
        istatistiksel bilgileri elde etmeye ve kullanıcılar için özel tasarlanmış kullanıcı sayfalarından &nbsp;dinamik olarak reklam ve içerik üretilmesine yardımcı olur. Teknik
        iletişim dosyası, ana bellekte veya e-postanızdan veri veya başkaca herhangi bir kişisel bilgi almak için tasarlanmamıştır. Tarayıcıların pek çoğu başta teknik iletişim
        dosyasını kabul eder biçimde tasarlanmıştır ancak kullanıcılar dilerse teknik iletişim dosyasının gelmemesi veya teknik iletişim dosyasının gönderildiğinde uyarı
        verilmesini sağlayacak biçimde ayarları değiştirebilirler.
      </div>
      <div>
        <br />
      </div>
      <div>
        Firmamız, işbu "Gizlilik Politikası" hükümlerini dilediği zaman sitede yayınlamak veya kullanıcılara elektronik posta göndermek veya sitesinde yayınlamak suretiyle
        değiştirebilir. Gizlilik Politikası hükümleri değiştiği takdirde, yayınlandığı tarihte yürürlük kazanır.
      </div>
      <div>
        <br />
      </div>
      <div>
        Gizlilik politikamız ile ilgili her türlü soru ve önerileriniz için &nbsp;
        <a href="mailto:bilgi@apithon.com.tr">bilgi@apithon.com.tr</a> adresine email gönderebilirsiniz. Firmamız’a ait aşağıdaki iletişim bilgilerinden ulaşabilirsiniz.
      </div>
      <div>
        <br />
      </div>
      <div>
        Firma Ünvanı<span className="Apple-tab-span"></span>
        :&nbsp;Apithon Software
      </div>
      <div>
        Adres<span className="Apple-tab-span"></span>:&nbsp; Atatürk Üniversitesi Yerleşkesi İpekyolu Caddesi Teknoloji Geliştirme Bölgesi B Blok No:305 Pk. 25240 Yakutiye ERZURUM
      </div>
      <div>
        Eposta<span className="Apple-tab-span"></span>:&nbsp;
        <a href="mailto:bilgi@apithon.com.tr">bilgi@apithon.com.tr</a>
      </div>
      <div>
        <br />
      </div>
      <div>
        <br />
      </div>
    </div>
  </>
);

export default PrivacyAndSecurity;
