import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useAppSelector } from 'src/services/redux/app/store';

const REACT_APP_API_URL = `${process.env.REACT_APP_API_URL}`;

export interface IResultParams {
  results?: Array<any> | null | undefined | any;
  totalResults: number | null;
  totalPages: number | null;
  page?: number | null;
  limit?: number | null;
}
export interface IRequestParams {
  name?: string | null;
  identity?: number | null;
  page?: number | null | undefined;
  limit?: number | null;
  sortBy?: string | null;
  tabState?: any;
}

export const useBranchesList = (onError?: any) => {
  const { user } = useAppSelector((state) => state.auth);
  const requestParams = { company_id: user?.company_id };
  const requestUrl = REACT_APP_API_URL + '/branches/list';
  const fetchData = async () => {
    const res = await axios.get(`${requestUrl}`, { params: requestParams });
    return res?.data || {};
  };
  return useQuery(['branches-list'], fetchData, {
    refetchOnWindowFocus: false,
    onError,
  });
};

export const useBranchesCreate = (onSuccess?: any, onError?: any) => {
  const { user } = useAppSelector((state) => state.auth);
  const requestUrl = REACT_APP_API_URL + '/branches/create';
  return useMutation(
    (formPayload: any) => {
      return axios.post(`${requestUrl}`, { ...formPayload, company_id: user?.company_id });
    },
    { onError, onSuccess },
  );
};

export const useBranchesUpdate = (onSuccess?: any, onError?: any) => {
  const { user } = useAppSelector((state) => state.auth);
  const requestUrl = REACT_APP_API_URL + '/branches/update';
  return useMutation(
    (formPayload: any) => {
      return axios.post(`${requestUrl}`, { ...formPayload, company_id: user?.company_id });
    },
    { onError, onSuccess },
  );
};

export const useBranchesDelete = (onSuccess?: any, onError?: any) => {
  const { user } = useAppSelector((state) => state.auth);
  const requestUrl = REACT_APP_API_URL + '/branches/delete';
  return useMutation(
    (formPayload: any) => {
      return axios.post(`${requestUrl}`, { ...formPayload, company_id: user?.company_id });
    },
    { onError, onSuccess },
  );
};
