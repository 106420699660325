import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PermissionContext from './permission-context';
import { Permission } from './types';

const usePermission = (permission: Permission) => {
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(true);
  const [allowed, setAllowed] = useState<boolean>();
  const { isAllowedTo } = useContext(PermissionContext);

  useEffect(() => {
    isAllowedTo(permission).then((allowed) => {
      setLoading(false);
      setAllowed(allowed);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return [loading, allowed];
};

export default usePermission;
