import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useAppDispatch, useAppSelector } from 'src/services/redux/app/store';
import { companyList, _logOut } from 'src/services/redux/slices/auth/auth-slice';
import { IProfileDetails, IUpdateEmail } from '../components/settings/SettingsModel';

const REACT_APP_API_URL = `${process.env.REACT_APP_API_URL}`;

export const useCompanyUpdate = (onSuccess?: any, onError?: any) => {
  const requestUrl = REACT_APP_API_URL + '/company/update';
  return useMutation<any, any, any, any>(
    (formPayload: IProfileDetails) => {
      const formData: any = new FormData();
      formData.append('id', formPayload?.id);
      typeof formPayload.company_logo === 'object' && formData.append('company_logo', formPayload?.company_logo);
      formData.append('company_name', formPayload?.company_name);
      formData.append('contact_name', formPayload?.contact_name);
      formData.append('contact_surname', formPayload?.contact_surname);
      formData.append('contact_phone', formPayload?.contact_phone?.value);
      return axios.post(`${requestUrl}`, formData);
    },
    { onSuccess, onError },
  );
};

export const useChangeEmail: any = (onSuccess?: any, onError?: any) => {
  const requestUrl = REACT_APP_API_URL + '/auth/change-email';
  return useMutation<any, any, any, any>(
    (formPayload: IUpdateEmail) => {
      return axios.post(`${requestUrl}`, formPayload);
    },
    { onSuccess, onError },
  );
};

export const useChangeTwoFactor: any = (onSuccess?: any, onError?: any) => {
  const dispatch = useAppDispatch();
  const requestUrl = REACT_APP_API_URL + '/auth/change-two-factor';
  return useMutation<any, any, any, any>(
    async (formPayload: any) => {
      const res = await axios.post(`${requestUrl}`, formPayload);
      await dispatch(companyList());
      return res;
    },
    { onSuccess, onError },
  );
};

export const useGibAuth = (onSuccess?: any, onError?: any) => {
  const { user } = useAppSelector((state) => state.auth);
  const requestUrl = REACT_APP_API_URL + '/company/gib/auth';
  return useMutation(
    (formPayload: any) => {
      return axios.post(`${requestUrl}`, { ...formPayload, company_id: user?.company_id });
    },
    { onError, onSuccess },
  );
};

export const useGibDelete = (onSuccess?: any, onError?: any) => {
  const { user } = useAppSelector((state) => state.auth);
  const requestUrl = REACT_APP_API_URL + '/company/gib/delete';
  return useMutation(
    (formPayload: any) => {
      return axios.post(`${requestUrl}`, { ...formPayload, company_id: user?.company_id });
    },
    { onError, onSuccess },
  );
};

// export const useControlGib = (params: any, onError?: any) => {
//   const { user } = useAppSelector((state) => state.auth);
//   const requestParams = { ...params, company_id: user?.company_id };
//   const requestUrl = REACT_APP_API_URL + '/company/gib/control';
//   const fetchData = async () => {
//     const res = await axios.get(`${requestUrl}`, { params: requestParams });
//     return res?.data || {};
//   };
//   return useQuery(['company-gib'], fetchData, {
//     refetchOnWindowFocus: false,
//     onError,
//     enabled: false,
//   });
// };

// export const useControlGib = (onSuccess?: any, onError?: any) => {
//   const { user } = useAppSelector((state) => state.auth);
//   const requestUrl = REACT_APP_API_URL + '/company/gib/control';
//   return useMutation(
//     (formPayload: any) => {
//       return axios.get(`${requestUrl}`, { ...formPayload, company_id: user?.company_id });
//     },
//     { onError, onSuccess },
//   );
// };

export const useVerifyAndSaveGib = (onSuccess?: any, onError?: any) => {
  const { user } = useAppSelector((state) => state.auth);
  const requestUrl = REACT_APP_API_URL + '/company/gib/verifyandsave';
  return useMutation(
    (formPayload: any) => {
      return axios.post(`${requestUrl}`, { ...formPayload, company_id: user?.company_id });
    },
    { onError, onSuccess },
  );
};

export const useControlGib = (onError?: any) => {
  const { user } = useAppSelector((state) => state.auth);
  const requestParams = { company_id: user?.company_id };
  const requestUrl = REACT_APP_API_URL + '/company/gib/control';
  const fetchData = async () => {
    const res = await axios.get(`${requestUrl}`, { params: requestParams });
    return res?.data || {};
  };
  return useQuery(['gib-control'], fetchData, {
    refetchOnWindowFocus: false,
    onError,
  });
};

// export const useControlGib = (onSuccess?: any, onError?: any) => {
//   const { user } = useAppSelector((state) => state.auth);
//   const requestUrl = REACT_APP_API_URL + '/company/gib/control';
//   return useMutation(
//     (formPayload: any) => {
//       return axios.post(`${requestUrl}`, { ...formPayload, company_id: user?.company_id });
//     },
//     { onError, onSuccess },
//   );
// };

export const useAllBanksList = (onError?: any) => {
  const { user } = useAppSelector((state) => state.auth);
  const requestUrl = REACT_APP_API_URL + '/banks/all-list';
  const fetchData = async () => {
    const res = await axios.get(`${requestUrl}`);
    return res?.data || {};
  };
  return useQuery(['banks-all-list'], fetchData, {
    refetchOnWindowFocus: false,
    onError,
  });
};
export const useChangeEmailPreferences: any = (onSuccess?: any, onError?: any) => {
  const requestUrl = REACT_APP_API_URL + '/auth/change-email-preferences';
  return useMutation<any, any, any, any>(
    async (formPayload: any) => {
      const res = await axios.post(`${requestUrl}`, formPayload);
      return res;
    },
    { onSuccess, onError },
  );
};

export const useChangePassword: any = (onSuccess?: any, onError?: any) => {
  const requestUrl = REACT_APP_API_URL + '/auth/change-password';
  return useMutation<any, any, any, any>(
    (formPayload: IUpdateEmail) => {
      return axios.post(`${requestUrl}`, formPayload);
    },
    { onSuccess, onError },
  );
};

export const useUsersDeactive: any = (onSuccess?: any, onError?: any) => {
  const dispatch = useAppDispatch();
  const requestUrl = REACT_APP_API_URL + '/auth/user-deactive';
  return useMutation<any, any, any, any>(
    async (formPayload: IUpdateEmail) => {
      const res = await axios.post(`${requestUrl}`, formPayload);
      dispatch(_logOut());
      return res;
    },
    { onSuccess, onError },
  );
};

export const useUploadImage = (onError?: any) => {
  const requestUrl = REACT_APP_API_URL + '/upload';
  return useMutation<any, any, any, any>(
    (formPayload: any) => {
      const formData: any = new FormData();
      formData.append('images', formPayload);
      return axios.post(`${requestUrl}`, formData);
    },
    { onError },
  );
};

export const useMarketPlaceCreate: any = (onSuccess?: any, onError?: any) => {
  const { user } = useAppSelector((state) => state.auth);
  const requestUrl = REACT_APP_API_URL + '/iyzipay/market-place/create';
  return useMutation<any, any, any, any>(
    (formPayload: any) => {
      return axios.post(`${requestUrl}`, { ...formPayload, company_id: user?.company_id });
    },
    { onSuccess, onError },
  );
};
