import React from 'react';
import { Link } from 'react-router-dom';
import { KTSVG } from 'src/_metronic/helpers';

type Props = {};

const NotAllowed = (props: Props) => {
  return (
    <div className="d-flex flex-column flex-center text-center p-10">
      {/*begin::Wrapper*/}
      <div className="card card-flush w-lg-650px py-5">
        <div className="card-body py-15 py-lg-20">
          {/*begin::Logo*/}
          <div className="mb-14">
            <KTSVG path="/media/icons/duotone/General/Shield-protected.svg" className="svg-icon svg-icon-5x svg-icon-primary  " />
          </div>

          {/*end::Logo*/}
          {/*begin::Title*/}
          <h1 className="text-gray-800 mb-5">Bu sayfaya erişim izniniz bulunmuyor</h1>
          {/*end::Title*/}
          {/*begin::Text*/}
          <div className="fs-6 text-gray-500 mb-8">
            Eğer bir yanlışlık olduğunuz düşünüyorsanız
            <br />
            yöneticiniz ile iletişime geçin.
          </div>
          {/*end::Text*/}
          {/*begin::Link*/}
          <div className="mb-11">
            <Link to={'/dashboard'} className="btn btn-secondary">
              Anasayfaya Git
            </Link>
          </div>
          {/*end::Link*/}
          {/*begin::Illustration*/}

          {/*end::Illustration*/}
        </div>
      </div>
      {/*end::Wrapper*/}
    </div>
  );
};
export default NotAllowed;
