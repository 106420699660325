import axios from 'axios';
import { AuthModel } from '../models/AuthModel';
import { UserModel } from '../models/UserModel';

const API_URL = process.env.REACT_APP_API_URL;

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/get-user`;
export const LOGIN_URL = `${API_URL}/auth/login`;
export const GOOGLE_LOGIN_URL = `${API_URL}/auth/google/login`;
export const REGISTER_URL = `${API_URL}/company/create`;
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot-password`;
export const RESET_PASSWORD_URL = `${API_URL}/auth/reset-password`;
export const TWO_FACTOR_URL = `${API_URL}/auth/two-factor`;

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post(LOGIN_URL, { email, password });
}

// Server should return AuthModel
export async function register(values: any) {
  return (await axios.post(REGISTER_URL, values)).data;
}

export function googleLogin(values: any) {
  return axios.post(GOOGLE_LOGIN_URL, values);
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ email: string }>(REQUEST_PASSWORD_URL, { email });
}

export function twoFactorLogin(values: any) {
  return axios.post<{ result: boolean }>(TWO_FACTOR_URL, { ...values });
}

export function resetPassword(token: string, password: string, email: string) {
  return axios.post<{ result: boolean }>(RESET_PASSWORD_URL, { email, token, password });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL);
}
